import { IRequest, RestService } from "../services/restService/restService";
import { useCallback, useEffect, useRef, useState } from "react";

export type IEncounteredErrorAction = {
  iconName?: string;
  onClick: () => void;
  text: string;
};

export type IEncounteredError = {
  text?: string;
  iconName?: string;
  actions?: IEncounteredErrorAction[];
};

type IUseGetDataProps = {
  request: IRequest;
  mapData?: (data: any) => any;
  onGetData?: (data: any) => void;
  prerequiredData?: Omit<IUseGetDataProps, "prerequiredData">[];
  skipFetch?: boolean;
};

const emptyState: any = {
  isLoading: true,
  data: undefined,
  error: undefined,
  _ts: undefined,
};

export function useGetData<T>({ mapData, onGetData, ...props }: IUseGetDataProps) {
  const [state, setState] = useState<{
    isLoading: boolean;
    data: T | undefined;
    error: IEncounteredError | undefined;
    _ts: number | undefined;
  }>(emptyState);

  const isMounted = useRef<boolean>(false);
  const abortController = useRef<AbortController | undefined>(undefined);

  const prerequiredData = useRef(props.prerequiredData);

  const fetchData = useCallback(() => {
    abortController.current?.abort();
    abortController.current = new AbortController();

    setState({ ...emptyState, isLoading: true });

    const restGetFinal = () => {
      RestService.get({
        env: props.request.env,
        path: props.request.path,
        route: props.request.route,
        signal: abortController.current?.signal,
      }).then((result) => {
        if (!isMounted.current) {
          return;
        }

        if (result.isOk) {
          const resultData = mapData ? mapData(result.data) : result.data;
          onGetData?.(resultData);
          setState({ ...emptyState, isLoading: false, data: resultData, _ts: result._ts });
        } else if (!result.wasCanceled) {
          setState({
            ...emptyState,
            isLoading: false,
            _ts: result._ts,
            error: {
              text: result.errorDetails.reason ?? "Something went wrong!",
              actions: [{ text: "Try again!", onClick: fetchData }],
            },
          });
        }
      });
    };

    if (prerequiredData.current) {
      Promise.all(
        prerequiredData.current.map((pd) => {
          return RestService.get({
            ...pd.request,
            ...{ signal: abortController.current?.signal },
          }).then((result) => {
            if (!isMounted.current) {
              return;
            }

            pd.onGetData?.(result.data);
          });
        }),
      )
        .then(() => {
          restGetFinal();
        })
        .catch((reason) => {
          if (!isMounted.current) {
            return;
          }

          setState({
            ...emptyState,
            isLoading: false,
            error: {
              text: reason?.toString() ?? "Something went wrong!",
              actions: [{ text: "Try again!", onClick: fetchData }],
            },
          });
        });
    } else {
      restGetFinal();
    }
  }, [props.request.env, props.request.path, props.request.route, onGetData, mapData]);

  useEffect(() => {
    if (!props.skipFetch) {
      isMounted.current = true;

      fetchData();

      return () => {
        isMounted.current = false;
        abortController.current?.abort();
      };
    }
  }, [fetchData, props.skipFetch]);

  return { ...state, fetchData };
}
