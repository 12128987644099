import SunCalc from "suncalc";

export enum TimeOfDay {
  MORNING = "morningSky",
  NOON = "noonSky",
  EVENING = "eveningSky",
  NIGHTSKY = "nightSky",
}

interface Position {
  latitude: number;
  longitude: number;
}

let sunTimes: SunCalc.GetTimesResult;

export const getTimeOfDay = (position: Position, date: Date = new Date()): TimeOfDay => {
  // Only calculate sun times if they have not been calculated yet
  if (!sunTimes) {
    sunTimes = SunCalc.getTimes(date, position.latitude, position.longitude);
  }
  const now = date.getTime();
  if (now >= sunTimes.sunrise.getTime() && now < sunTimes.solarNoon.getTime() - 3600000 * 3) {
    return TimeOfDay.MORNING;
  } else if (now >= sunTimes.solarNoon.getTime() - 3600000 * 3 && now < sunTimes.sunset.getTime() - 3600000 * 2) {
    return TimeOfDay.NOON;
  } else if (now >= sunTimes.sunset.getTime() - 3600000 * 2 && now < sunTimes.night.getTime() - 5700000) {
    return TimeOfDay.EVENING;
  } else {
    return TimeOfDay.NIGHTSKY;
  }
};
