import moment from "moment-timezone";
import StatusContainer from "../../../../../_common/StatusContainer";
import StatusValue from "../../../../../_common/StatusValue";
import { getStateContainerClass } from "../../utils";
import { IDevice } from "../../../../../../../types/Device";
import { useId, useState } from "react";
import { useMqttProperty } from "../../../../../../../hooks/useMqttClient";
import {
  mdiArrowBottomLeft,
  mdiArrowCollapseDown,
  mdiArrowCollapseRight,
  mdiArrowCollapseUp,
  mdiArrowCollapseVertical,
  mdiArrowTopRight,
} from "@mdi/js";

export default function SiteState(props: { device: IDevice }) {
  const componentId = useId();

  const [state, setState] = useState<{
    isLoading: boolean;
    dateTime: string;

    importLimit: number | undefined;
    exportLimit: number | undefined;
    exportPeakshavingLimit: number | undefined;
  }>({
    isLoading: true,
    dateTime: "N/A",

    importLimit: undefined,
    exportLimit: undefined,
    exportPeakshavingLimit: undefined,
  });

  useMqttProperty(
    "site",
    componentId,
    10_000,
    (data: {
      import_limit: number;
      export_peakshaving_limit: number;
      export_limit: number;
      metadata: {
        utc_timestamp: number;
      };
    }) => {
      setState({
        exportLimit: data.export_limit,
        importLimit: data.import_limit,
        exportPeakshavingLimit: data.export_peakshaving_limit,

        dateTime:
          data.metadata.utc_timestamp != null
            ? moment.tz(data.metadata.utc_timestamp * 1000, "Europe/Ljubljana").format("DD.MM.yyyy HH:mm:ss")
            : "N/A",
        isLoading: false,
      });
    }
  );

  return (
    <>
      <StatusContainer
        title="Site limits"
        dateTime={state.dateTime}
        icon={mdiArrowCollapseVertical}
        className={getStateContainerClass("site")}
      >
        <StatusValue
          isLoading={state.isLoading}
          value={props.device.measuring_point.import_limit}
          description="DNO import limit"
          icon={mdiArrowBottomLeft}
          unit="W"
        />
        <StatusValue
          isLoading={state.isLoading}
          value={props.device.measuring_point.export_limit}
          description="DNO export limit"
          icon={mdiArrowTopRight}
          unit="W"
        />

        <StatusValue
          isLoading={state.isLoading}
          value={state.importLimit}
          description="Peakshaving import limit"
          icon={mdiArrowCollapseDown}
          userEditable
          unit="W"
          editable={{
            dataKey: "import-limit",
            type: "numberInput",
          }}
        />

        <StatusValue
          isLoading={state.isLoading}
          value={state.exportLimit}
          description="Peakshaving export limit"
          icon={mdiArrowCollapseUp}
          userEditable
          unit="W"
          editable={{
            dataKey: "export-limit",
            type: "numberInput",
          }}
        />
        <StatusValue
          isLoading={state.isLoading}
          value={state.exportPeakshavingLimit}
          description="Export feedIn priority"
          icon={mdiArrowCollapseRight}
          unit="W"
          userEditable
          editable={{
            dataKey: "export-peakshaving-limit",
            type: "numberInput",
          }}
        />
      </StatusContainer>
    </>
  );
}
