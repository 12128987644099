import Container from "../../../../decorators/container/Container";
import { IChargeGroup, IChargeGroupConfig } from "../../../../types/ChargeGroup";
import { mdiCogs } from "@mdi/js";
import { RestEnv } from "../../../../services/restService/restUtils";
import { useCallback } from "react";
import { useGetData } from "../../../../hooks/useGetData";
import { Value } from "../../../device/deviceLayout/customerData/_shared/Value";

type IResponse = {
  status: string;
  data?: IChargeGroupConfig[];
};

export function ChangeGroupConfig(props: { chargeGroup: IChargeGroup }) {
  const configFetch = useGetData<IChargeGroupConfig>({
    request: {
      env: RestEnv.DATA,
      path: `/justcharge/api/v1/groups/config/id/${props.chargeGroup.id}`,
    },
    mapData: useCallback((data: IResponse) => {
      return data.data?.[0];
    }, []),
  });

  const currency = knownCurrencies[configFetch.data?.currency || ""] || "💵";

  return (
    <Container title="Configuration" icon={mdiCogs} isCollapseable>
      <div className="flex flex-col gap-2">
        <Value
          label="Free charge time"
          value={parseValue(configFetch.data?.configs.free_charge_time.value, "min")}
          isLoading={configFetch.isLoading}
        />
        <Value
          label="Charge time"
          value={parseValue(configFetch.data?.configs.charge_time.value, "min")}
          isLoading={configFetch.isLoading}
        />

        <Value
          label="Charge price"
          value={parseValue(configFetch.data?.configs.charge_price.value, `${currency} / kWh`)}
          isLoading={configFetch.isLoading}
        />
        <Value
          label="Precharge price"
          value={parseValue(configFetch.data?.configs.precharge_price.value, currency)}
          isLoading={configFetch.isLoading}
        />
        <Value
          label="Postcharge price"
          value={parseValue(configFetch.data?.configs.postcharge_price.value, `${currency} / min`)}
          isLoading={configFetch.isLoading}
        />
      </div>
    </Container>
  );
}

function parseValue(value: string | undefined, unit: string) {
  if (value == null) {
    return "N/A";
  }

  return `${value} ${unit}`;
}

const knownCurrencies: any = {
  EUR: "€",
  GBP: "£",
  PLN: "zł",
  CZK: "Kč",
  HUF: "Ft",
  RON: "lei",
  HRK: "kn",
  RSD: "din",
  MKD: "ден",
  RUB: "₽",
  UAH: "₴",
};
