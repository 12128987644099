import { ChargeGroup } from "./views/chargeGroup/ChargeGroup";
import { ChargeGroupList } from "./views/chargeGroupList/ChargeGroupList";
import { DarkModeProvider } from "./layout/darkModeToggle/DarkModeToggle";
import { Dashboard } from "./views/dashboard/Dashboard";
import { Device } from "./views/device/Device";
import { DeviceList } from "./views/deviceList/DeviceList";
import { init } from "./i18n/config";
import { Layout } from "./layout/Layout";
import { Navigate, Route, Routes } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { UserRestrictedArea, useUser } from "./layout/userRestrictedArea/UserRestrictedArea";

init("en");

export default function App() {
  return (
    <>
      <DarkModeProvider>
        <div className="h-full min-h-screen w-full bg-brand-50 text-brand-950 dark:bg-brand-950 dark:text-brand-100">
          <UserRestrictedArea>
            <AppRoutes />
          </UserRestrictedArea>
        </div>
      </DarkModeProvider>
      <Toaster position="bottom-right" toastOptions={{ className: "bg-brand-950 text-brand-100 text-sm" }} />
    </>
  );
}

function AppRoutes() {
  const user = useUser();

  // Uncomment to test super admin permissions
  // user.user!.permissions.isSuperAdmin = true;

  return (
    <Routes>
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/" element={<Layout />}>
        <Route index element={<Navigate to={"/device"} />} />
        <Route path="device" element={<DeviceList />} />
        <Route path="device/:id" element={<Device />} />
        {user.user?.permissions?.isSuperAdmin && (
          <>
            <Route path="chargeGroup" element={<ChargeGroupList />} />
            <Route path="chargeGroup/:id" element={<ChargeGroup />} />
          </>
        )}
        <Route path="*" element={<Navigate to={"/"} />} />
      </Route>
    </Routes>
  );
}
