import Lottie from "lottie-react";
import React from "react";
import { LottieAnimationProps } from "./interfaces/AnimationsInterface";

const LottieAnimation: React.FC<LottieAnimationProps> = ({ animationData, id }) => {
  return (
    <Lottie
      id={id}
      animationData={animationData}
      loop={true}
      className="absolute bg-no-repeat"
      style={{ width: "100%", pointerEvents: "none" }}
    />
  );
};

export default LottieAnimation;
