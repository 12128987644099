import Icon from "@mdi/react";
import { clsx } from "../../../../utils/clsx";
import { Fragment, useEffect, useState } from "react";
import { mdiCheck, mdiChevronDown } from "@mdi/js";
import { Menu, Transition } from "@headlessui/react";
import { useLocation, useNavigate } from "react-router-dom";

const items = [
  { key: "device", name: "Devices" },
  { key: "chargeGroup", name: "justCHARGE" },
];

export function EntitySelector() {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedItem, setSelectedItem] = useState(items[0]);

  useEffect(() => {
    const itemKey = location.pathname.split("/")[1];
    const item = items.find((i) => i.key === itemKey);
    if (item) {
      setSelectedItem(item);
    }
  }, [location.pathname]);

  const changeSelectedItem = (item: { key: string; name: string }) => {
    setSelectedItem(item);

    switch (item.key) {
      case "device":
        navigate("/device" + location.search || "");
        break;
      case "chargeGroup":
        navigate("/chargeGroup" + location.search || "");
        break;
      default:
        console.error("Unknown selected navigation itemKey: " + item.key);
        break;
    }
  };

  return (
    <Menu
      as="div"
      className="relative inline-block rounded-md border-r border-brand-200 bg-brand-300  text-left dark:border-brand-700 dark:bg-brand-700"
    >
      <Menu.Button className="flex h-full w-full items-center justify-center gap-1 rounded-md px-2 text-brand-600 dark:text-brand-400">
        {selectedItem.name}
        <Icon path={mdiChevronDown} className="h-5 w-5" />
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          defaultValue={selectedItem.key}
          className="absolute right-0 z-10 mt-2 min-w-40 origin-top-right rounded-md bg-brand-100 shadow ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-brand-900"
        >
          <div className="py-1">
            {items.map((item) => (
              <Menu.Item key={item.key}>
                {({ active }) => (
                  <div
                    onClick={() => changeSelectedItem(item)}
                    className={clsx(
                      active ? "bg-brand-200 text-brand-900 dark:bg-brand-800 dark:text-brand-100" : " text-brand-800 dark:text-brand-200",
                      "flex cursor-pointer gap-1 px-4 py-2 text-sm",
                    )}
                  >
                    <span className="size-5">{item.key === selectedItem.key && <Icon path={mdiCheck} className="h-5 w-5" />}</span>
                    {item.name}
                  </div>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
