import { IDevicePlantType } from "../../../../../../types/Device";
import { getDeviceTypeDef, IEnergyIO, IPossibleIO } from "../historySummaryStats/utils";
import { PercentageStats } from "./utils";

export function SummaryHistoryPercentage(props: {
  selectedIoKey: IPossibleIO;
  energySources: IEnergyIO | null | undefined;
  energyDestinations: IEnergyIO | null | undefined;
  devicePlantType: IDevicePlantType | undefined;
}) {
  if (!props.energySources || !props.energyDestinations) {
    return null;
  }

  const def = (() => {
    switch (props.selectedIoKey) {
      case "load":
        return {
          key: "Home energy sources",
          items: [
            getDeviceTypeDef({
              type: "photovoltaic",
              val: props.energySources.load.photovoltaic.percent,
              plantType: props.devicePlantType,
            }),
            getDeviceTypeDef({ type: "battery", val: props.energySources.load.battery.percent }),
            getDeviceTypeDef({ type: "grid", val: props.energySources.load.grid.percent }),
          ],
        };
      case "photovoltaic":
        return {
          key: "Solar energy destinations",
          items: [
            getDeviceTypeDef({ type: "load", val: props.energyDestinations.photovoltaic.load.percent }),
            getDeviceTypeDef({ type: "battery", val: props.energyDestinations.photovoltaic.battery.percent }),
            getDeviceTypeDef({ type: "grid", val: props.energyDestinations.photovoltaic.grid.percent }),
          ],
        };
      case "battery":
        return {
          key: "Battery charge sources",
          items: [
            getDeviceTypeDef({
              type: "photovoltaic",
              val: props.energySources.battery.photovoltaic.percent,
              plantType: props.devicePlantType,
            }),
            getDeviceTypeDef({ type: "grid", val: props.energySources.battery.grid.percent }),
          ],
        };
      case "grid":
        return {
          key: "Grid energy destinations",
          items: [
            getDeviceTypeDef({ type: "load", val: props.energyDestinations.grid.load.percent }),
            getDeviceTypeDef({ type: "battery", val: props.energyDestinations.grid.battery.percent }),
          ],
        };
    }
  })();

  return <PercentageStats title={def.key} items={def.items} />;
}
