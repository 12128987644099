import Container from "../../../../../decorators/container/Container";
import Icon from "@mdi/react";
import moment from "moment";
import { LoadingIcon } from "../../../../../decorators/loading/Loading";
import { mdiBattery80, mdiClockOutline, mdiCodeTagsCheck } from "@mdi/js";
import { useId, useState } from "react";
import { useMqttProperty } from "../../../../../hooks/useMqttClient";
import { Value } from "../_shared/Value";
import { parseValuesToArray } from "../../deviceDetails/DeviceDetails.utils";

type Ibms = {
  master: number | undefined;
  slave1: number | undefined;
  slave2: number | undefined;
  slave3: number | undefined;
  slave4: number | undefined;
  slave5: number | undefined;
  slave6: number | undefined;
  slave7: number | undefined;
  slave8: number | undefined;
  slave9: number | undefined;
};

export default function AboutFirmware() {
  const componentId = useId();

  const [state, setState] = useState<{
    isLoading: boolean;
    dateTime: string;
    master: any;
    slave: any;
    manager: any;

    _bmsXXX: {
      index: number;
      value: Ibms;
    }[];
  }>({
    isLoading: true,
    dateTime: "N/A",
    master: "N/A",
    slave: "N/A",
    manager: "N/A",

    _bmsXXX: [],
  });

  useMqttProperty(
    "firmware-versions",
    componentId,
    10_000,
    (data: {
      master: number;
      slave: number;
      manager: number;
      metadata: {
        utc_timestamp: number;
      };

      bms1?: Ibms;
      bms2?: Ibms;
      // bms3?: Ibms; ...

      //#region legacy
      batteryMaster: number;
      batterySlave1: number;
      batterySlave2: number;
      batterySlave3: number;
      batterySlave4: number;
      batterySlave5: number;
      batterySlave6: number;
      batterySlave7: number;
      batterySlave8: number;
      batterySlave9: number;
      //#endregion legacy
    }) => {
      const { master, slave, manager, metadata, ...bmses } = data;
      const bmsessss = data.bms1
        ? parseValuesToArray({ startsWith: "bms", values: bmses })
        : [
            {
              index: 1,
              value: {
                master: data.batteryMaster,
                slave1: data.batterySlave1,
                slave2: data.batterySlave2,
                slave3: data.batterySlave3,
                slave4: data.batterySlave4,
                slave5: data.batterySlave5,
                slave6: data.batterySlave6,
                slave7: data.batterySlave7,
                slave8: data.batterySlave8,
                slave9: data.batterySlave9,
              },
            },
          ];

      setState({
        master,
        slave,
        manager,
        isLoading: false,
        _bmsXXX: bmsessss,
        dateTime:
          metadata.utc_timestamp != null
            ? moment.tz(metadata.utc_timestamp * 1000, "Europe/Ljubljana").format("DD.MM.yyyy HH:mm:ss")
            : "N/A",
      });
    }
  );

  return (
    <>
      <Container title="Software version" icon={mdiCodeTagsCheck} isCollapseable>
        <div className="flex flex-col gap-2">
          <Value label="Master version" value={state.master} isLoading={state.isLoading} />
          <Value label="Slave version" value={state.slave} isLoading={state.isLoading} />
          <Value label="Manager version" value={state.manager} isLoading={state.isLoading} />

          <div className="flex items-center gap-2 italic">
            <div className="flex items-center gap-1 font-mono text-xs font-extralight italic text-brand-500">
              <Icon path={mdiClockOutline} className="h-3 w-3" />
              {state.dateTime}
            </div>
            {state.isLoading && <LoadingIcon />}
          </div>
        </div>
      </Container>

      {state._bmsXXX.length > 1 ? (
        <Container title="Batteries capacities" isCollapseable icon={mdiBattery80}>
          <div className="grid grid-cols-2 gap-2">
            {state._bmsXXX.map((bms) => (
              <Battery key={bms.index} batteries={bms.value} isLoading={state.isLoading} dateTime={state.dateTime} tightView />
            ))}
          </div>
          <div className="mt-2 flex items-center justify-center gap-1 font-mono text-xs font-extralight italic text-brand-500">
            <Icon path={mdiClockOutline} className="h-3 w-3" />
            {state.dateTime}
          </div>
        </Container>
      ) : (
        <Container title="Battery capacity" isCollapseable icon={mdiBattery80}>
          <Battery batteries={state._bmsXXX[0]?.value ?? { master: undefined }} isLoading={state.isLoading} dateTime={state.dateTime} />
        </Container>
      )}
    </>
  );
}

function Battery(props: {
  batteries: { master: number | undefined; [bKey: string]: number | undefined };
  isLoading: boolean;
  dateTime: string;
  tightView?: boolean;
}) {
  const height = 140;
  let prevPower = 0;
  let totalPower = 0;

  const { master, ...otherBatteries } = props.batteries;

  const batteries = Object.entries(otherBatteries).reduce((pv, [bKey, bValue]) => {
    if (bValue != null && bValue > 0) {
      const newPower = bValue / 10 - prevPower;
      totalPower += newPower;
      pv.push({ key: bKey, value: bValue, power: newPower });
      prevPower = bValue / 10;
    }
    return pv;
  }, [] as { key: string; value: number; power: number }[]);

  return (
    <div>
      <div className="flex justify-center">
        <div
          className="rounded border border-brand-200 bg-brand-100 dark:border-brand-900 dark:bg-brand-900 "
          style={{ width: "30%", height: `${height / 10}px`, marginBottom: "-2px" }}
        />
      </div>
      <div className="flex flex-col gap-2 rounded border border-brand-200 bg-brand-100 p-2 shadow-sm dark:border-brand-900 dark:bg-brand-900">
        {props.isLoading ? (
          <div className="flex items-center justify-center" style={{ height: `${height}px` }}>
            <LoadingIcon />
          </div>
        ) : (
          <>
            {batteries.map((b) => (
              <div
                key={b.key}
                className="flex w-full items-center justify-center rounded bg-ngen bg-opacity-50"
                style={{ height: `${height / batteries.length}px`, minHeight: "2em" }}
              >
                {b.power.toFixed(2)} kWh
              </div>
            ))}
            {batteries.length === 0 && <div className="text-center text-brand-700">No batteries</div>}
            {batteries.length > 0 && (
              <div>
                <div className="text-center text-brand-700 dark:text-brand-300">
                  {props.tightView ? "Σ" : "Total capacity:"} {totalPower.toFixed(2)}kWh
                </div>
                <div className="text-center text-xs text-brand-500">
                  {props.tightView ? "ver." : "Battery master version:"} {master}
                </div>
              </div>
            )}

            {!props.tightView && (
              <div className="flex items-center justify-center gap-1 font-mono text-xs font-extralight italic text-brand-500">
                <Icon path={mdiClockOutline} className="h-3 w-3" />
                {props.dateTime}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}
