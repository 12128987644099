import { IDeviceDef } from "../historySummaryStats/utils";
import { mdiPercentOutline } from "@mdi/js";
import { PercentageStats } from "./utils";

export function RawHistoryPercentage(props: { impact: { self_sufficiency: number } }) {
  if (!props.impact) {
    return null;
  }

  const items: IDeviceDef[] = [
    {
      key: "self_sufficiency",
      className: "text-green-600",
      bgClassName: "bg-green-500",
      label: "Self efficiency",
      value: props.impact.self_sufficiency,
      icon: mdiPercentOutline,
      unit: "%",
    },
  ];

  return <PercentageStats title="Efficiency" items={items} />;
}
