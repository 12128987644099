import moment from "moment-timezone";
import StatusContainer from "../../../../../_common/StatusContainer";
import StatusValue from "../../../../../_common/StatusValue";
import { getStateContainerClass } from "../../utils";
import { mdiSigma, mdiSolarPanelLarge } from "@mdi/js";
import { parseValuesToArray } from "../../../DeviceDetails.utils";
import { useId, useState } from "react";
import { useMqttProperty } from "../../../../../../../hooks/useMqttClient";

type IData = {
  timestamp: number;
  device: {
    readings: {
      param: string;
      value: string;
      unit: string;
    }[];
  };
};

type IState = {
  isLoading: boolean;
  data:
    | {
        time: string;
        total: {
          value: number;
          unit: string;
        };
        _pvXXX: {
          index: number;
          value:
            | {
                value: number;
                unit: string;
              }
            | undefined;
          iconPath: string;
          unit?: string;
        }[];
      }
    | undefined;
};

export default function SolarStatePwrBank() {
  const componentId = useId();

  const [state, setState] = useState<IState>({ isLoading: true, data: undefined });

  useMqttProperty("status/solar", componentId, 0, (data: IData) => {
    const { Psum_kW, ...other } = data.device.readings.reduce(
      (acc, reading) => {
        acc[reading.param] = {
          param: reading.param,
          value: Number(reading.value),
          unit: reading.unit,
        };
        return acc;
      },
      {} as Record<
        string,
        {
          param: string;
          value: number;
          unit: string;
        }
      >,
    );

    setState({
      isLoading: false,
      data: {
        time: moment.tz(data.timestamp * 1000, "Europe/Ljubljana").format("DD.MM.yyyy HH:mm:ss"),
        total: {
          value: Psum_kW.value,
          unit: Psum_kW.unit,
        },
        _pvXXX: parseValuesToArray({ startsWith: "P", values: other }),
      },
    });
  });

  return (
    <StatusContainer title="Solar state" dateTime={state.data?.time} icon={mdiSolarPanelLarge} className={getStateContainerClass("solar")}>
      <StatusValue
        isLoading={state.isLoading}
        value={state.data?.total.value}
        description="Total solar power"
        icon={mdiSigma}
        unit={state.data?.total.unit}
      />

      {state.data?._pvXXX?.map((pv) => (
        <StatusValue
          key={pv.index}
          value={pv.value?.value}
          description={`Solar string ${pv.index}`}
          icon={pv.iconPath}
          unit={pv.value?.unit}
        />
      ))}
    </StatusContainer>
  );
}
