import { ChargeGroupStations } from "../ChargeGroupStations/ChargeGroupStations";
import { IChargeGroup } from "../../../types/ChargeGroup";

export function ChangeGroupDetails(props: { chargeGroup: IChargeGroup }) {
  return (
    <div className="flex w-full flex-col gap-3">
      <ChargeGroupStations chargeGroup={props.chargeGroup} />
    </div>
  );
}
