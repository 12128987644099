import Icon from "@mdi/react";
import { clsx } from "../../../utils/clsx";

export default function ContentSelector(props: {
  items: { key: string; name: string; badge?: number; colorClassName?: string; icon?: string; hidden?: boolean }[];
  selectedKey: string;
  onSelection: (panelKey: string) => void;
}) {
  const filteredItems = props.items.filter((item) => !item.hidden);

  return (
    <>
      <div className="sm:hidden">
        <label htmlFor="current-tab" className="sr-only">
          Select a tab
        </label>
        <select
          id="current-tab"
          name="current-tab"
          className="block w-full rounded-md border border-brand-100  bg-brand-100 py-1.5 pl-3 pr-10 shadow dark:border-brand-950 dark:bg-brand-900  "
          defaultValue={props.selectedKey}
          onChange={(event) => props.onSelection(event.target.value)}
        >
          {filteredItems.map((tab) => (
            <option key={tab.key} value={tab.key}>
              {tab.name}
              {tab.badge != null && <> - ({tab.badge})</>}
            </option>
          ))}
          {filteredItems.length === 0 && <option disabled>No tabs available</option>}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav className="flex flex-wrap space-x-2 border-b-2 border-brand-100 dark:border-brand-900">
          {filteredItems.length === 0 && (
            <div className="-mb-0.5 border-transparent px-2 py-2 text-sm font-medium">
              <span className="text-gray-400">No tabs available</span>
            </div>
          )}
          {filteredItems.map((tab) => {
            const needsSupport = tab.key === "devicesNeededSupport" && (tab.badge || 0) > 0;
            return (
              <div
                key={tab.key}
                onClick={() => props.onSelection(tab.key)}
                className={clsx(
                  "",
                  tab.key === props.selectedKey
                    ? "border-ngen text-ngen"
                    : "border-transparent hover:border-brand-300 dark:hover:border-brand-700 ",
                  "-mb-0.5 cursor-pointer whitespace-nowrap border-b-2 px-2 py-2 text-sm font-medium",
                  needsSupport ? "text-orange-700 dark:text-orange-400" : "",
                )}
              >
                {tab.colorClassName != null && (
                  <>
                    {tab.icon ? (
                      <Icon path={tab.icon} className={`mr-2 inline-block h-4 w-4 ${tab.colorClassName}`} />
                    ) : (
                      <span className={`mr-2  inline-block h-2.5 w-2.5 rounded-full border border-brand-500 ${tab.colorClassName}`}></span>
                    )}
                  </>
                )}

                <span className={needsSupport ? "text-shadow animate-pulse-fast shadow-orange-600" : ""}>{tab.name}</span>
                {tab.badge != null && (
                  <span className="ml-2 rounded border border-brand-200 bg-brand-50 px-2.5 py-0.5 text-xs font-medium text-brand-500 dark:border-brand-800 dark:bg-brand-950 dark:text-brand-500">
                    <span className="text-xs font-semibold">{tab.badge}</span>
                  </span>
                )}
              </div>
            );
          })}
        </nav>
      </div>
    </>
  );
}
