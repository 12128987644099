import { IDevice } from "../../../types/Device";

export function getDeviceCustomerAndMeasurementPoint(device: IDevice) {
  return { name: device.owner_name || "N/A", mp: device.measuring_point_odoo_mpid ? `MM-${device.measuring_point_odoo_mpid}` : "N/A" };
}

export function getMatchingDevices(devices: IDevice[], query: string | null) {
  if (!query) {
    return devices;
  }

  return devices.filter((d) => {
    // return (
    //   d.name.toLowerCase().includes(loweredQuery) ||
    //   d.measuring_point.address.toLowerCase().includes(loweredQuery) ||
    //   d.device.ip.toLowerCase().includes(loweredQuery) ||
    //   d.meter_serial_number.toLowerCase().includes(loweredQuery)
    // );

    return valExistsInObject(d, query);
  });
}

export function valExistsInObject(obj: any, val: string): boolean {
  val = val.toLowerCase();
  return Object.values(obj).some((v: any) => {
    if (v && typeof v === "object") {
      return valExistsInObject(v, val);
    }

    const valueString = v?.toString().toLowerCase() || "";
    const isMatching = valueString.includes(val);

    return isMatching;
  });
}
