import Container from "../../../../../decorators/container/Container";
import Icon from "@mdi/react";
import moment from "moment";
import { LoadingIcon } from "../../../../../decorators/loading/Loading";
import { mdiChip, mdiClockOutline } from "@mdi/js";
import { useId, useState } from "react";
import { useMqttProperty } from "../../../../../hooks/useMqttClient";
import { Value } from "../_shared/Value";

export function AboutInverter() {
  const componentId = useId();

  const [state, setState] = useState<{
    isLoading: boolean;
    dateTime: string;
    inverterModel: string;
    inverterSerialNumber: string;
  }>({
    isLoading: true,
    dateTime: "N/A",
    inverterModel: "N/A",
    inverterSerialNumber: "N/A",
  });

  useMqttProperty("site", componentId, 10_000, (data: { metadata: any; inverter_model: string; inverter_serial_number: string }) => {
    setState({
      inverterModel: data.inverter_model,
      inverterSerialNumber: data.inverter_serial_number,
      isLoading: false,
      dateTime:
        data.metadata.utc_timestamp != null
          ? moment.tz(data.metadata.utc_timestamp * 1000, "Europe/Ljubljana").format("DD.MM.yyyy HH:mm:ss")
          : "N/A",
    });
  });

  return (
    <Container title="Model" icon={mdiChip} isCollapseable>
      <div className="flex flex-col gap-2">
        <Value label="Inverter model" value={state.inverterModel} isLoading={state.isLoading} />
        <Value label="Inverter serial number" value={state.inverterSerialNumber} isLoading={state.isLoading} />

        <div className="flex items-center gap-2 italic">
          <div className="flex items-center gap-1 font-mono text-xs font-extralight italic text-brand-500">
            <Icon path={mdiClockOutline} className="h-3 w-3" />
            {state.dateTime}
          </div>
          {state.isLoading && <LoadingIcon />}
        </div>
      </div>
    </Container>
  );
}
