import Container from "../../../../../../decorators/container/Container";
import { IDevicePlantType } from "../../../../../../types/Device";
import { getDeviceTypeDef, Measures } from "./utils";

export function RawHistoryStats(props: {
  energySummary: IEnergySummary | null | undefined;
  devicePlantType: IDevicePlantType | undefined;
}) {
  if (!props.energySummary) {
    return null;
  }

  const items = [
    getDeviceTypeDef({ type: "load", val: props.energySummary.load.import, pointType: "destination" }),
    getDeviceTypeDef({
      type: "photovoltaic",
      val: props.energySummary.photovoltaic.export,
      pointType: "source",
      plantType: props.devicePlantType,
    }),
    getDeviceTypeDef({ type: "battery", val: props.energySummary.battery.export, pointType: "source" }),
    getDeviceTypeDef({ type: "battery", val: props.energySummary.battery.import, pointType: "destination" }),
    getDeviceTypeDef({ type: "grid", val: props.energySummary.grid.import, pointType: "source" }),
    getDeviceTypeDef({ type: "grid", val: props.energySummary.grid.export, pointType: "destination" }),
  ];

  return (
    <Container title="Summary" variant="small" isCollapseable>
      <Measures items={items} />
    </Container>
  );
}

export type IEnergySummary = {
  grid: IEnergySummaryValue;
  load: IEnergySummaryValue;
  photovoltaic: IEnergySummaryValue;
  battery: IEnergySummaryValue;
};

type IEnergySummaryValue = {
  import: number;
  import_average?: number;
  export: number;
  export_average?: number;
  combined: number;
  combined_average?: number;
};
