import moment from "moment";
import StatusContainer from "../../../../../_common/StatusContainer";
import StatusValue from "../../../../../_common/StatusValue";
import { getStateContainerClass } from "../../utils";
import { parseValuesToArray } from "../../../DeviceDetails.utils";
import { useId, useState } from "react";
import { useMqttProperty } from "../../../../../../../hooks/useMqttClient";
import {
  mdiArrowBottomLeft,
  mdiArrowTopRight,
  mdiBattery,
  mdiBattery80,
  mdiBatteryCharging10,
  mdiBatteryCharging100,
  mdiBatteryCharging20,
  mdiBatteryCharging30,
  mdiBatteryCharging40,
  mdiBatteryCharging50,
  mdiBatteryCharging60,
  mdiBatteryCharging70,
  mdiBatteryCharging80,
  mdiBatteryCharging90,
  mdiBatteryChargingOutline,
  mdiMeterElectricOutline,
  mdiThermometer,
} from "@mdi/js";

export default function BatteryState() {
  const componentId = useId();

  const [state, setState] = useState<{
    isLoading: boolean;
    dateTime: string;
    power: number | undefined;
    soc: number | undefined;
    temperature: number | undefined;
    available_battery_power_charge: number | undefined;
    available_battery_power_discharge: number | undefined;

    _bmsXXX: { index: number; value: number | undefined; iconPath: string }[];
  }>({
    isLoading: true,
    dateTime: "N/A",
    power: undefined,
    soc: undefined,
    temperature: undefined,
    available_battery_power_charge: undefined,
    available_battery_power_discharge: undefined,
    _bmsXXX: [],
  });

  useMqttProperty(
    "battery",
    componentId,
    10_000,
    (data: {
      soc: number;
      power: number;
      temperature: number;
      available_battery_power_charge: number;
      available_battery_power_discharge: number;
      bms1?: { soc: number };
      bms2?: { soc: number };
      // bms3, bms4, ...
      metadata: {
        utc_timestamp: number;
      };
    }) => {
      const { power, soc, temperature, available_battery_power_charge, available_battery_power_discharge, metadata, ...other } = data;

      setState({
        power,
        soc,
        temperature,
        available_battery_power_charge,
        available_battery_power_discharge,
        dateTime:
          metadata.utc_timestamp != null
            ? moment.tz(metadata.utc_timestamp * 1000, "Europe/Ljubljana").format("DD.MM.yyyy HH:mm:ss")
            : "N/A",
        isLoading: false,
        _bmsXXX: other.bms1 ? parseValuesToArray({ startsWith: "bms", values: other }) : [],
      });
    },
  );

  return (
    <>
      <StatusContainer title="Battery state" dateTime={state.dateTime} icon={mdiBattery80} className={getStateContainerClass("battery")}>
        <StatusValue isLoading={state.isLoading} value={state.power} description="Power" icon={mdiMeterElectricOutline} unit="W" />
        <StatusValue
          isLoading={state.isLoading}
          value={state.soc}
          description={state._bmsXXX.length > 0 ? "Average SOC" : "SOC"}
          icon={getBatteryIcon(state.soc)}
          unit="%"
        />
        <StatusValue
          isLoading={state.isLoading}
          value={state.available_battery_power_charge}
          description="Available battery power charge"
          icon={mdiArrowBottomLeft}
          unit="W"
        />
        <StatusValue
          isLoading={state.isLoading}
          value={state.available_battery_power_discharge}
          description="Available battery power discharge"
          icon={mdiArrowTopRight}
          unit="W"
        />
        <StatusValue isLoading={state.isLoading} value={state.temperature} description="Temperature" icon={mdiThermometer} unit="°C" />
        {state._bmsXXX.map((bms) => {
          const val = (bms.value as any).soc;
          return (
            <StatusValue
              key={bms.index}
              isLoading={state.isLoading}
              value={val}
              description={`BMS ${bms.index} SOC`}
              icon={getBatteryIcon(val)}
              unit="%"
            />
          );
        })}
      </StatusContainer>
    </>
  );
}

export function getBatteryIcon(value: number | undefined) {
  const val = value == null ? undefined : Math.round(value / 10) * 10;

  switch (val) {
    case 0:
      return mdiBatteryChargingOutline;
    case 10:
      return mdiBatteryCharging10;
    case 20:
      return mdiBatteryCharging20;
    case 30:
      return mdiBatteryCharging30;
    case 40:
      return mdiBatteryCharging40;
    case 50:
      return mdiBatteryCharging50;
    case 60:
      return mdiBatteryCharging60;
    case 70:
      return mdiBatteryCharging70;
    case 80:
      return mdiBatteryCharging80;
    case 90:
      return mdiBatteryCharging90;
    case 100:
      return mdiBatteryCharging100;
    default:
      return mdiBattery;
  }
}
