import { LoadingIcon } from "../../../../../decorators/loading/Loading";

export function Value(props: { label: string; value: any; isLoading?: boolean }) {
  return (
    <div>
      <div className="-mb-1 text-brand-700 dark:text-brand-500">{props.label}</div>
      <div className="flex items-center gap-2">
        <span>{props.value}</span>
        {props.isLoading && <LoadingIcon />}
      </div>
    </div>
  );
}
