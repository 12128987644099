import DatePickerInput from "../../../../../components/inputs/datePickerInput/DatePickerInput";
import Icon from "@mdi/react";
import { add, format, sub } from "date-fns";
import { mdiFilterCheckOutline, mdiRefresh, mdiTransferLeft, mdiTransferRight } from "@mdi/js";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";

type IProps = {
  onNewDate: (data: { year: number; month: number; day: number }) => void;
  refreshData: () => void;
  type: "day" | "month" | "year";
};

function getDateNow(type: "day" | "month" | "year") {
  const now = new Date();
  const year = now.getFullYear();
  const month = type === "year" ? 1 : now.getMonth() + 1;
  const day = type === "day" ? now.getDate() : 5;

  return {
    stringValue: `${year}-${String(month).padStart(2, "0")}-${String(day).padStart(2, "0")}`,
    year,
    month,
    day,
  };
}

export default function HistoryToolbar(props: IProps) {
  const [state, setState] = useState(getDateNow(props.type));

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
  } = useForm<{ date: string }>({ defaultValues: { date: state.stringValue } });

  const substractDay = useCallback(() => {
    const newData = sub(new Date(state.year, state.month - 1, state.day), {
      days: props.type === "day" ? 1 : 0,
      months: props.type === "month" ? 1 : 0,
      years: props.type === "year" ? 1 : 0,
    });
    setValue("date", format(newData, "yyyy-MM-dd"));
  }, [state, setValue, props.type]);

  const addDay = useCallback(() => {
    const newData = add(new Date(state.year, state.month - 1, state.day), {
      days: props.type === "day" ? 1 : 0,
      months: props.type === "month" ? 1 : 0,
      years: props.type === "year" ? 1 : 0,
    });
    setValue("date", format(newData, "yyyy-MM-dd"));
  }, [state, setValue, props.type]);

  const onSubmit = (data: { date: string }) => {
    const [year, month, day] = data.date.split("-");
    setState({
      day: Number(day),
      month: Number(month),
      year: Number(year),
      stringValue: data.date,
    });
  };

  useEffect(() => {
    props.onNewDate({
      day: state.day,
      month: state.month,
      year: state.year,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.onNewDate, state]);

  return (
    <div className="mb-2 rounded-sm">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex items-end gap-1">
          <div className="grow">
            <DatePickerInput
              type={props.type}
              control={control}
              label="Date"
              register={register("date", { required: "Date is required" })}
              error={errors.date}
            />
          </div>

          <button
            type="submit"
            style={{
              // maxHeight: "2.6em", lineHeight: "3em"
              lineHeight: "1em",
            }}
            className="flex items-center justify-center gap-2 rounded border border-transparent bg-ngen px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-emerald-500  "
          >
            <Icon path={mdiFilterCheckOutline} className="h-4 w-4" />
            Apply
          </button>

          <button
            onClick={props.refreshData}
            className="flex items-center justify-center gap-2 rounded border border-transparent bg-brand-700 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-emerald-500  "
          >
            <Icon path={mdiRefresh} className="h-4 w-4" />
          </button>

          <button
            onClick={substractDay}
            className="flex items-center justify-center gap-2 rounded border border-transparent bg-brand-700 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-emerald-500  "
          >
            <Icon path={mdiTransferLeft} className="h-4 w-4" />
          </button>

          <button
            onClick={addDay}
            className="flex items-center justify-center gap-2 rounded border border-transparent bg-brand-700 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-emerald-500  "
          >
            <Icon path={mdiTransferRight} className="h-4 w-4" />
          </button>
        </div>
      </form>
    </div>
  );
}
