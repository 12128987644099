import DeviceLayout from "./deviceLayout/DeviceLayout";
import { IDevice } from "../../types/Device";
import { Loading } from "../../decorators/loading/Loading";
import { MQTTData, useMqttClient } from "../../hooks/useMqttClient";
import { RestEnv } from "../../services/restService/restUtils";
import { StateDialogProvider } from "./_common/editStateDialog/StateDialog.utils";
import { useCallback } from "react";
import { useGetData } from "../../hooks/useGetData";
import { useParams } from "react-router";

export function Device() {
  const { id: deviceId } = useParams();

  const fetch = useGetData<IDevice>({
    request: {
      env: RestEnv.DATA,
      path: "/api/1.0/device/" + deviceId,
    },
    mapData: useCallback((data: any) => data.data.find((d: IDevice) => d.device.id === deviceId), [deviceId]),
  });

  if (fetch.isLoading) {
    return (
      <div className="w-full max-w-7xl p-2">
        <Loading text="Fetching device ..." iconPosition="top" />
      </div>
    );
  }

  if (fetch.data == null) {
    return (
      <div className="w-full max-w-7xl p-2">
        <div>Device not found</div>
      </div>
    );
  }

  return <DeviceContent device={fetch.data} />;
}

function DeviceContent({ device }: { device: IDevice }) {
  const { connected, ...clientProps } = useMqttClient(device.device.type === "powerbank" ? "powerbank" : "ngenstar", device.device.id);

  return (
    <>
      <MQTTData.Provider value={clientProps}>
        <StateDialogProvider>
          <DeviceLayout device={device} connected={connected} />
        </StateDialogProvider>
      </MQTTData.Provider>
    </>
  );
}
