import { HighchartsReactRefObject } from "highcharts-react-official";
import { RefObject, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export const usePeakShaving = (props: { chartRef: RefObject<HighchartsReactRefObject> }) => {
  const { t } = useTranslation();
  const [state, setState] = useState({
    importLimit: 0,
    exportLimit: 0,
    showImportLimit: false,
    showExportLimit: false,
    divideBy1000: false,
  });

  useEffect(() => {
    try {
      props.chartRef?.current?.chart?.yAxis[0].removePlotLine("importLimit");
      props.chartRef?.current?.chart?.yAxis[0].removePlotLine("exportLimit");

      if (state.showImportLimit) {
        props.chartRef?.current?.chart?.yAxis[0].addPlotLine({
          id: "importLimit",
          value: state.importLimit / (state.divideBy1000 ? 1000 : 1),
          color: "white",
          zIndex: 4,
          dashStyle: "Dot",
          label: {
            text: t("peakShaving.current_import_limit"),
            align: "left",
            textAlign: "left",
            rotation: 0,
            y: -10,
            style: {
              color: "white",
            },
          },
        });
      }

      if (state.showExportLimit) {
        props.chartRef?.current?.chart?.yAxis[0].addPlotLine({
          id: "exportLimit",
          value: state.exportLimit / (state.divideBy1000 ? 1000 : 1),
          color: "white",
          zIndex: 4,
          dashStyle: "Dot",
          label: {
            text: t("peakShaving.current_export_limit"),
            align: "left",
            textAlign: "left",
            rotation: 0,
            y: -10,
            style: {
              color: "white",
            },
          },
        });
      }
    } catch (error) {
      console.error("Error adding plot line", error);
    }
  }, [props.chartRef, t, state]);

  const showImportLimits = useCallback((props: { importLimit: number, divideBy1000?: boolean }) => {
    setState((prev) => ({ ...prev, showImportLimit: true, importLimit: props.importLimit, divideBy1000: Boolean(props?.divideBy1000) }));
  }, []);

  const hideImportLimits = useCallback(() => {
    setState((prev) => ({ ...prev, showImportLimit: false }));
  }, []);

  const showExportLimits = useCallback((props: { exportLimit: number, divideBy1000?: boolean }) => {
    setState((prev) => ({ ...prev, showExportLimit: true, exportLimit: props.exportLimit, divideBy1000: Boolean(props?.divideBy1000) }));
  }, []);

  const hideExportLimits = useCallback(() => {
    setState((prev) => ({ ...prev, showExportLimit: false }));
  }, []);

  return {
    showImportLimits,
    hideImportLimits,
    showExportLimits,
    hideExportLimits,
  };
};
