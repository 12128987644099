import { FieldError, UseFormRegisterReturn } from "react-hook-form";

export default function ComboBoxInput(props: {
  label: string;
  register: UseFormRegisterReturn;
  error?: FieldError;

  options: { key: string; value: string | number }[];
}) {
  const hasError = props.error?.message;

  return (
    <div>
      <label htmlFor={props.register.name} className="block text-sm font-medium">
        {props.label}
      </label>
      <div
        className={
          "relative mt-1 flex  overflow-hidden rounded  border-b  focus-within:border-ngen " +
          (hasError ? "border-red-600" : "border-brand-300")
        }
      >
        <select
          {...props.register}
          className={
            "block w-full border-0 border-b border-transparent bg-brand-100 text-sm focus:border-ngen focus:ring-0 dark:bg-brand-900 "
          }
          autoComplete="off"
        >
          {props.options.map((option, index) => (
            <option key={index} value={option.key}>
              {option.value}
            </option>
          ))}
        </select>
      </div>
      {hasError && <div className="mt-0.5 text-xs font-light text-red-600">{props.error?.message}</div>}
    </div>
  );
}
