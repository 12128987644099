import DotSpinner from "./dotSpinner/DotSpinner";
import LottieAnimation from "./LottieAnimation";
import React, { useEffect, useState } from "react";
import windImg from "./assets/windImg.png";
import windIn from "./assets/windIn.json";
import windOut from "./assets/windOut.json";
import { getTimeOfDay, TimeOfDay } from "./helpers/SunCalcModule";
import { SmartMeter } from "./types/AnimationsTypes";
import { useTranslation } from "react-i18next";

interface AnimationProps {
  className?: string;
  opacity?: number;
  smartMeter: SmartMeter;
}

const AnimationWind: React.FC<AnimationProps> = ({ className, opacity, smartMeter }) => {
  const { t } = useTranslation();
  const formatPower = (value: any) => {
    const kWValue = Math.abs(value / 1000).toFixed(1);
    return parseFloat(kWValue) % 1 === 0 ? parseFloat(kWValue).toFixed(0) : kWValue;
  };

  const [timeOfDay, setTimeOfDay] = useState<TimeOfDay | null>(null);
  const getFormattedTimestamp = () => {
    let date = new Date();
    return date.toLocaleString("sl-SI");
  };
  const shouldDisplayTimestamp = () => {
    return smartMeter.power !== null;
  };
  useEffect(() => {
    try {
      const position = { latitude: 46.0569, longitude: 14.5058 }; // Ljubljana
      const time = getTimeOfDay(position);
      setTimeOfDay(time);
    } catch (error) {
      console.error("Error getting time of day:", error);
    }
  }, [smartMeter]);
  return (
    <div className={"sky py-3 " + (timeOfDay || "")}>
      <div
        id="animationHome"
        className={`relative ${className ? className : ""} animationHome`}
        style={{ height: "auto", opacity: opacity }}
      >
        {(smartMeter.power || 0) < 0 && <LottieAnimation id="windOut" animationData={windOut} />}
        {(smartMeter.power || 0) > 0 && <LottieAnimation id="windIn" animationData={windIn} />}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width="100%"
          height="100%"
          viewBox="0 200 1082 1138"
        >
          <defs>
            <clipPath id="dchda">
              <path
                fill="#fff"
                d="M909.448 848c6.351 0 11.5 5.149 11.5 11.5s-5.149 11.5-11.5 11.5-11.5-5.149-11.5-11.5 5.149-11.5 11.5-11.5z"
              />
            </clipPath>
          </defs>
          <g id="timestamp">
            <text fill="white" fontFamily="inherit" fontSize="28" transform="translate(776 1260)">
              {shouldDisplayTimestamp() && <tspan dy="0.8em">{getFormattedTimestamp()}</tspan>}
            </text>
          </g>
          <g>
            <g>
              <image width="1082" height="1138" transform="translate(0 200)" xlinkHref={windImg} />
            </g>
            <g>
              <g id="gridLine">
                <path fill="#7e8a8a" d="M859 984h-1V794h1z" transform="translate(0 245)" />
              </g>
              <g id="gridDot">
                <path
                  fill="#7e8a8a"
                  d="M909.448 848c6.351 0 11.5 5.149 11.5 11.5s-5.149 11.5-11.5 11.5-11.5-5.149-11.5-11.5 5.149-11.5 11.5-11.5z"
                  transform="translate(-51 180)"
                />
                <path
                  fill="none"
                  stroke="#5eba47"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeMiterlimit="20"
                  strokeWidth="12"
                  d="M909.448 848c6.351 0 11.5 5.149 11.5 11.5s-5.149 11.5-11.5 11.5-11.5-5.149-11.5-11.5 5.149-11.5 11.5-11.5z"
                  clipPath='url("#dchda")'
                  transform="translate(-51 180)"
                />
              </g>
              <g>
                <g>
                  <g transform="translate(710 1170)">
                    {smartMeter.power === null ? (
                      <DotSpinner />
                    ) : (
                      <text
                        fill="#fff"
                        dominantBaseline="text-before-edge"
                        fontFamily="inherit"
                        fontSize="3rem"
                        transform="translate(125 -30)"
                        textAnchor="end"
                      >
                        <tspan dy="0.5em">{formatPower(smartMeter["power"])} kW</tspan>
                      </text>
                    )}
                  </g>
                </g>
                <g>
                  <g>
                    <text
                      fill="#5eba47"
                      dominantBaseline="text-before-edge"
                      fontFamily="inherit"
                      fontSize="2rem"
                      transform="translate(835 1100)"
                      textAnchor="end"
                    >
                      <tspan dy="0.8em">{t("animation.grid")}</tspan>
                    </text>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </div>
  );
};

export default AnimationWind;
