import moment from "moment-timezone";
import StatusContainer from "../../../../../_common/StatusContainer";
import StatusValue from "../../../../../_common/StatusValue";
import { getStateContainerClass } from "../../utils";
import { mdiNumeric2CircleOutline, mdiSigma, mdiSolarPanelLarge } from "@mdi/js";
import { parseValuesToArray } from "../../../DeviceDetails.utils";
import { useId, useState } from "react";
import { useMqttProperty } from "../../../../../../../hooks/useMqttClient";

export default function SolarState() {
  const componentId = useId();
  const [state, setState] = useState<{
    isLoading: boolean;
    power: {
      _pvXXX: { index: number; value: number | undefined; iconPath: string }[];
      pv_meter2: number | undefined;
      total: number | undefined;
    };
    dateTime: string;
  }>({
    isLoading: true,
    power: {
      _pvXXX: [],
      pv_meter2: undefined,
      total: undefined,
    },
    dateTime: "N/A",
  });

  useMqttProperty(
    "photovoltaic",
    componentId,
    10_000,
    (data: {
      power: {
        pv_meter2: number;
        total: number;
        pv1?: number;
        pv2?: number;
        pv3?: number;
        // pv4, pv5, ...
      };
      metadata: {
        utc_timestamp: number;
      };
    }) => {
      const { pv_meter2, total, ...other } = data.power;

      return setState({
        power: {
          _pvXXX: parseValuesToArray({ startsWith: "pv", values: other }),
          pv_meter2,
          total,
        },
        dateTime:
          data.metadata.utc_timestamp != null
            ? moment.tz(data.metadata.utc_timestamp * 1000, "Europe/Ljubljana").format("DD.MM.yyyy HH:mm:ss")
            : "N/A",
        isLoading: false,
      });
    }
  );

  return (
    <StatusContainer title="Solar state" dateTime={state.dateTime} icon={mdiSolarPanelLarge} className={getStateContainerClass("solar")}>
      <StatusValue isLoading={state.isLoading} value={state.power.total} description="Total solar power" icon={mdiSigma} unit="W" />
      <StatusValue
        isLoading={state.isLoading}
        value={state.power.pv_meter2}
        description="Solar meter 2"
        icon={mdiNumeric2CircleOutline}
        unit="W"
      />

      {state.power._pvXXX.map((pv) => (
        <StatusValue key={pv.index} value={pv.value} description={`Solar string ${pv.index}`} icon={pv.iconPath} />
      ))}
    </StatusContainer>
  );
}
