import Icon from "@mdi/react";
import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { mdiLightbulb, mdiLightbulbOff } from "@mdi/js";

export function DarkModeToggle() {
  const { isDarkMode, toggleDarkMode } = useDarkMode();

  return (
    <div className="">
      <span className="sr-only">Use setting</span>
      <div
        onClick={toggleDarkMode}
        className={
          "shadown relative m-2 flex items-center justify-center  transition-opacity  hover:cursor-pointer hover:text-ngen " +
          (isDarkMode ? "text-yellow-500" : "")
        }
      >
        <span className="h-5 w-5">
          <span
            className={"absolute " + (isDarkMode ? "opacity-0 duration-100 ease-out" : "opacity-100 duration-200 ease-in")}
            aria-hidden="true"
          >
            <Icon path={mdiLightbulbOff} className="h-5 w-5" />
          </span>
          <span
            className={"absolute " + (isDarkMode ? "opacity-100 duration-200 ease-in" : "opacity-0 duration-100 ease-out")}
            aria-hidden="true"
          >
            <Icon path={mdiLightbulb} className="h-5 w-5" />
          </span>
        </span>
        <span className="text-sm">{isDarkMode ? "Turn on the lights" : "Turn off the lights"}</span>
      </div>
    </div>
  );
}

const DarkModeContext = createContext({
  isDarkMode: true,
  toggleDarkMode: () => {},
});

export function useDarkMode() {
  return useContext(DarkModeContext);
}

export const DarkModeProvider = (props: { children: ReactNode }) => {
  const [isDarkMode, setIsDarkMode] = useState(true);

  useEffect(() => {
    if (localStorage.theme === "light") {
      setIsDarkMode(false);
      document.documentElement.classList.remove("dark");
    } else {
      setIsDarkMode(true);
      document.documentElement.classList.add("dark");
    }
  }, []);

  const toggleDarkMode = () => {
    setIsDarkMode((wasDark) => {
      const isDark = !wasDark;

      localStorage.theme = isDark ? "dark" : "light";

      if (isDark) {
        document.documentElement.classList.add("dark");
      } else {
        document.documentElement.classList.remove("dark");
      }

      return isDark;
    });
  };

  return (
    <div className="dark:bg-brand-950 dark:text-brand-100">
      <DarkModeContext.Provider value={{ isDarkMode, toggleDarkMode }}>{props.children}</DarkModeContext.Provider>
    </div>
  );
};
