import { createContext, ReactNode, useCallback, useContext, useRef } from "react";
import { EditStateDialog, IEditStateDialog, IInputDefinition } from "./EditStateDialog";
import { ILastStateDialog, LastStateDialog } from "./LastStateDialog";

export function StateDialogProvider(props: { children: ReactNode }) {
  const dialogRef = useRef<IEditStateDialog>(null);
  const viewLastChangeDialogRef = useRef<ILastStateDialog>(null);

  const showEditDialog = useCallback((dialogTitle: string, input: IInputDefinition) => {
    dialogRef.current?.showDialog(dialogTitle, input);
  }, []);

  const viewLastChangeDialog = useCallback((entityName: string, state: { value: any; user: any }) => {
    viewLastChangeDialogRef.current?.showDialog(entityName, state);
  }, []);

  return (
    <>
      <EditStateDialog ref={dialogRef} />
      <LastStateDialog ref={viewLastChangeDialogRef} />
      <DialogContext.Provider value={{ showEditDialog, viewLastChangeDialog }}>{props.children}</DialogContext.Provider>
    </>
  );
}

const DialogContext = createContext<{
  showEditDialog?: (dialogTitle: string, input: IInputDefinition) => void;
  viewLastChangeDialog?: (entityName: string, state: { value: any; user: any }) => void;
}>({});

export const useEditStatusValueDialog = () => {
  return useContext(DialogContext);
};
