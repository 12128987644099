import Icon from "@mdi/react";
import { LoadingIcon } from "../../../../../decorators/loading/Loading";
import { mdiAccountEditOutline } from "@mdi/js";

export function Value(props: {
  icon: string;
  value: any;
  unit?: string;
  description: any;
  userEditable?: boolean;
  isLoading?: boolean;
  valueClassName?: string;
}) {
  return (
    <>
      <div className="flex w-full items-center gap-2 rounded-md border border-brand-100 bg-brand-50 p-3 shadow-sm dark:border-brand-800 dark:bg-brand-900">
        <div className="flex-grow">
          <div className="flex items-center gap-2">
            <Icon path={props.icon} className="h-4 w-4" />
            <div className="flex items-center gap-2">
              <span className={"font-semibold uppercase " + props.valueClassName}>
                {props.value != null ? props.value + (props.unit ? ` ${props.unit}` : "") : "N/A"}
              </span>
              {props.isLoading && <LoadingIcon />}
            </div>
          </div>

          <div className="text-sm">{props.description}</div>
        </div>

        {props.userEditable && (
          <div className="text-brand-500">
            <NTooltip text="User-adjustable option">
              <Icon path={mdiAccountEditOutline} className="-mb-1 h-6 w-6" />
            </NTooltip>
          </div>
        )}
      </div>
    </>
  );
}

const NTooltip = ({ text, children }: { text: string; children: any }) => {
  return (
    <div className="relative">
      <div className="group hover:cursor-help">
        {children}
        <div className="absolute z-10 hidden whitespace-nowrap rounded bg-brand-800 p-2 text-xs text-white shadow group-hover:inline">
          {text}
        </div>
      </div>
    </div>
  );
};
