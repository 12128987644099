export const deviceTypes = ["ngenstar", "smart-meter", "powerbank", "megapack"] as const;
export const devicePlantTypes = ["solar", "hydroelectric", "wind", "biomass", "none"] as const;

export type IDevice = {
  owner_name?: string;
  measuring_point_odoo_mpid?: string;
  device: IDeviceMeta;
  online: string;
  software_version: string;
  meter_serial_number: string;
  last_online: string;
  supply_group: string;
  measuring_point: IMeasuringPoint;
  quality_control_date: string;
  quality_control_performed_by: string | null;
  start_date: string | null;
  project_partner?: IDeviceProjectPartner;
  contract?: IContract;
};

export type IDeviceProjectPartner = {
  id: number;
  name: string;
  email: string;
};

export type IDevicePlantType = (typeof devicePlantTypes)[number];

type IDeviceMeta = {
  id: string;
  ip: string;
  type: (typeof deviceTypes)[number];
  power_plant_type: IDevicePlantType | undefined;
};

type IMeasuringPoint = {
  address: string;
  zipcode: string;
  city: string;
  import_limit: number;
  export_limit: number;
  country: {
    name: string;
    iso_code: string;
  };
  odoo_id: number;
};

type IContract = {
  contract_type_id?: string;
  contract_type_description?: string;
  supply_start_date?: string;
  receive_start_date?: string;
};
