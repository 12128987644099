import Container from "../../../../../../decorators/container/Container";
import { getDeviceTypeDef, IDeviceDef, IEnergyIO, IPossibleIO, Measures } from "./utils";
import { IEnergySummary } from "./RawHistoryStats";
import { mdiAlignVerticalCenter, mdiSigma, mdiTransmissionTower, mdiTransmissionTowerOff } from "@mdi/js";
import { IDevicePlantType } from "../../../../../../types/Device";

export function SummaryHistoryStats(props: {
  selectedIoKey: IPossibleIO;
  energy: IEnergySummary | null | undefined;
  energySources: IEnergyIO | null | undefined;
  energyDestinations: IEnergyIO | null | undefined;
  impact: { self_sufficiency: number } | null | undefined;
  devicePlantType: IDevicePlantType | undefined;
}) {
  if (!props.energySources || !props.energyDestinations) {
    return null;
  }

  const sourcesAndDestinations = (() => {
    switch (props.selectedIoKey) {
      case "load":
        return [
          getDeviceTypeDef({
            type: "photovoltaic",
            val: props.energySources.load.photovoltaic.energy,
            pointType: "source",
            plantType: props.devicePlantType,
          }),
          getDeviceTypeDef({ type: "battery", val: props.energySources.load.battery.energy, pointType: "source" }),
          getDeviceTypeDef({ type: "grid", val: props.energySources.load.grid.energy, pointType: "source" }),
        ];
      case "battery":
        return [
          getDeviceTypeDef({
            type: "photovoltaic",
            val: props.energySources.battery.photovoltaic.energy,
            pointType: "source",
            plantType: props.devicePlantType,
          }),
          getDeviceTypeDef({ type: "grid", val: props.energySources.battery.grid.energy, pointType: "source" }),
          getDeviceTypeDef({ type: "load", val: props.energyDestinations.battery.load.energy, pointType: "destination" }),
          getDeviceTypeDef({ type: "grid", val: props.energyDestinations.battery.grid.energy, pointType: "destination" }),
        ];
      case "photovoltaic":
        return [
          getDeviceTypeDef({ type: "load", val: props.energyDestinations.photovoltaic.load.energy, pointType: "destination" }),
          getDeviceTypeDef({ type: "battery", val: props.energyDestinations.photovoltaic.battery.energy, pointType: "destination" }),
          getDeviceTypeDef({ type: "grid", val: props.energyDestinations.photovoltaic.grid.energy, pointType: "destination" }),
        ];
      case "grid":
        return [
          getDeviceTypeDef({ type: "load", val: props.energyDestinations.grid.load.energy, pointType: "destination" }),
          getDeviceTypeDef({ type: "battery", val: props.energyDestinations.grid.battery.energy, pointType: "destination" }),
          getDeviceTypeDef({
            type: "photovoltaic",
            val: props.energySources.grid.photovoltaic.energy,
            pointType: "source",
            plantType: props.devicePlantType,
          }),
          getDeviceTypeDef({ type: "battery", val: props.energySources.grid.battery.energy, pointType: "source" }),
        ];
      default:
        console.error("Unknown io key", props.selectedIoKey);
        return [];
    }
  })();

  const statistics = ((): IDeviceDef[] => {
    const result: IDeviceDef[] = [];

    switch (props.selectedIoKey) {
      case "load": {
        result.push({
          key: "load",
          className: "text-blue-400",
          bgClassName: "bg-brand-950",
          label: "Total energy consumption",
          value: props.energy?.load.import ?? 0,
          icon: mdiSigma,
          unit: "kWh",
        });

        if (props.energy?.load.import_average != null) {
          result.push({
            key: "loadAvg",
            className: "text-blue-400",
            bgClassName: "bg-brand-950",
            label: "Avg. energy consumption",
            value: props.energy.load.import_average ?? 0,
            icon: mdiAlignVerticalCenter,
            unit: "kWh",
            dir: "destination",
          });
        }

        return result;
      }

      case "battery":
        result.push({
          key: "battery",
          className: "text-green-600 dark:text-green-500",
          bgClassName: "bg-brand-950",
          label: "Total energy charge",
          value: props.energy?.battery.import ?? 0,
          icon: mdiSigma,
          unit: "kWh",
          dir: "destination",
        });

        result.push({
          key: "batteryDsg",
          className: "text-green-600 dark:text-green-500",
          bgClassName: "bg-brand-950",
          label: "Total energy discharge",
          value: props.energy?.battery.export ?? 0,
          icon: mdiSigma,
          unit: "kWh",
          dir: "source",
        });

        if (props.energy?.battery.import_average != null) {
          result.push({
            key: "batteryAvgChg",
            className: "text-green-600 dark:text-green-500",
            bgClassName: "bg-brand-950",
            label: "Avg. energy charge",
            value: props.energy.battery.import_average ?? 0,
            icon: mdiAlignVerticalCenter,
            unit: "kWh",
            dir: "destination",
          });
        }

        if (props.energy?.battery.export_average != null) {
          result.push({
            key: "batteryAvgDsg",
            className: "text-green-600 dark:text-green-500",
            bgClassName: "bg-brand-950",
            label: "Avg. energy discharge",
            value: props.energy.battery.export_average ?? 0,
            icon: mdiAlignVerticalCenter,
            unit: "kWh",
            dir: "source",
          });
        }

        result.push({
          key: "self_sufficiency",
          className: "text-green-600 dark:text-green-500",
          bgClassName: "bg-brand-950",
          label: "Self-powered",
          value: props.impact?.self_sufficiency ?? 0,
          icon: mdiTransmissionTowerOff,
          unit: "%",
        });

        return result;
      case "photovoltaic":
        result.push({
          key: "photovoltaic",
          className: "text-yellow-600 dark:text-yellow-500",
          bgClassName: "bg-brand-950",
          label: "Total energy production",
          value: props.energy?.photovoltaic.export ?? 0,
          icon: mdiSigma,
          unit: "kWh",
        });

        if (props.energy?.photovoltaic.export_average != null) {
          result.push({
            key: "loadAvg",
            className: "text-yellow-600 dark:text-yellow-500",
            bgClassName: "bg-brand-950",
            label: "Avg. energy production",
            value: props.energy.photovoltaic.export_average ?? 0,
            icon: mdiAlignVerticalCenter,
            unit: "kWh",
            dir: "source",
          });
        }

        return result;
      case "grid":
        result.push({
          key: "grid",
          className: "text-gray-500 dark:text-gray-400",
          bgClassName: "bg-gray-900",
          label: "Total energy consumption",
          value: props.energy?.grid.combined ?? 0,
          icon: mdiSigma,
          unit: "kWh",
        });

        result.push({
          key: "gridImp",
          className: "text-gray-500 dark:text-gray-400",
          bgClassName: "bg-brand-950",
          label: "Total energy import",
          value: props.energy?.grid.import ?? 0,
          icon: mdiTransmissionTower,
          dir: "destination",
          unit: "kWh",
        });

        result.push({
          key: "gridExp",
          className: "text-gray-500 dark:text-gray-400",
          bgClassName: "bg-brand-950",
          label: "Total energy export",
          value: props.energy?.grid.export ?? 0,
          icon: mdiTransmissionTower,
          dir: "source",
          unit: "kWh",
        });

        return result;
      default:
        console.error("Unknown io key", props.selectedIoKey);
        return [];
    }
  })();

  return (
    <>
      <Container title="Sources and destinations" variant="small" isCollapseable>
        <Measures items={sourcesAndDestinations} />
      </Container>

      {statistics.length > 0 && (
        <Container title="Statistics" variant="small" isCollapseable>
          <Measures items={statistics} />
        </Container>
      )}
    </>
  );
}
