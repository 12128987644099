export function getStateContainerClass(stateType: "battery" | "grid" | "inverter" | "site" | "solar" | undefined) {
  let className = "p-4 border border-l-4 rounded-md shadow ";

  switch (stateType) {
    case "battery":
      className +=
        "border-green-300 shadow-green-100 dark:border-green-500 dark:shadow-none bg-gradient-to-br from-green-300/10 to-brand-50 dark:from-green-500/10 dark:to-brand-900 ";
      break;
    case "site":
      className +=
        "border-blue-300 shadow-blue-100 dark:border-blue-500 dark:shadow-none bg-gradient-to-br from-blue-300/10 to-brand-50 dark:from-blue-500/10 dark:to-brand-900";
      break;
    case "inverter":
      className +=
        "border-violet-300 shadow-violet-100 dark:border-violet-500 dark:shadow-none bg-gradient-to-br from-violet-300/10 to-brand-50 dark:from-violet-500/10 dark:to-brand-900";
      break;
    case "solar":
      className +=
        "border-yellow-300 shadow-yellow-100 dark:border-yellow-500 dark:shadow-none bg-gradient-to-br from-yellow-300/10 to-brand-50 dark:from-yellow-500/10 dark:to-brand-900";
      break;
    case "grid":
      className +=
        "border-gray-300 shadow-gray-100 dark:border-gray-500 dark:shadow-none bg-gradient-to-br from-gray-300/10 to-brand-50 dark:from-gray-500/10 dark:to-brand-900";
      break;
    default:
      className +=
        "border-brand-300 shadow-brand-100 dark:border-brand-500 dark:shadow-none bg-gradient-to-br from-brand-300/10 to-brand-50 dark:from-brand-500/10 dark:to-brand-900";
      break;
  }

  return className;
}
