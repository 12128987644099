import ContentSelector from "../deviceList/_common/ContentSelector";
import DeviceListHeader from "../deviceList/DeviceListHeader";
import Icon from "@mdi/react";
import { getChargeGroupsByCountry } from "./ChargeGroupList.utils";
import { IChargeGroup } from "../../types/ChargeGroup";
import { Link, useSearchParams } from "react-router-dom";
import { Loading } from "../../decorators/loading/Loading";
import { mdiCurrencyUsd, mdiCurrencyUsdOff, mdiLockOpenVariantOutline, mdiLockOutline, mdiOpenInApp } from "@mdi/js";
import { RestEnv } from "../../services/restService/restUtils";
import { useCallback, useEffect, useState } from "react";
import { useGetData } from "../../hooks/useGetData";
import { useInView } from "react-intersection-observer";
import { valExistsInObject } from "../deviceList/_common/utils";

type IResponse = {
  status: string;
  data?: IChargeGroup[];
};

export function ChargeGroupList() {
  const fetch = useGetData<IChargeGroup[]>({
    request: {
      env: RestEnv.DATA,
      path: "/justcharge/api/v1/groups/all",
    },
    mapData: useCallback((data: IResponse) => {
      return data.data;
    }, []),
  });

  return (
    <div className="w-full max-w-7xl p-2">
      {fetch.isLoading ? (
        <Loading text="Fetching charge groups ..." iconPosition="top" />
      ) : (
        <ChargeGroupListDisplay chargeGroups={fetch.data || []} onRefresh={fetch.fetchData} />
      )}
    </div>
  );
}

function ChargeGroupListDisplay(props: { chargeGroups: IChargeGroup[]; onRefresh: () => void }) {
  const [searchParams] = useSearchParams();
  const query = searchParams.get("query");

  const [state, setState] = useState(
    (() => {
      const matchingChargeGroups = getMatchingChargeGroups(props.chargeGroups || [], query);
      return {
        chargeGroups: matchingChargeGroups,
        key: Date.now(),
      };
    })(),
  );

  useEffect(() => {
    const matchingChargeGroups = getMatchingChargeGroups(props.chargeGroups || [], query);
    setState({
      chargeGroups: matchingChargeGroups,
      key: Date.now(),
    });
  }, [query, props.chargeGroups]);

  return (
    <>
      <DeviceListHeader title="Charging groups" onClearNavigateTo="/chargeGroup" query={query} onRefresh={props.onRefresh} />
      <div className="my-6">
        <GroupsByCountry chargeGroups={state.chargeGroups} key={state.key} />
      </div>
    </>
  );
}

function getMatchingChargeGroups(chargeGroups: IChargeGroup[], query: string | null): IChargeGroup[] {
  if (!query) {
    return chargeGroups;
  }

  return chargeGroups.filter((d) => valExistsInObject(d, query));
}

function GroupsByCountry(props: { chargeGroups: IChargeGroup[] }) {
  const [groupedData] = useState(getChargeGroupsByCountry(props.chargeGroups));

  const [state, setState] = useState(groupedData[0]);

  const onTabSelection = useCallback(
    (tabKey: string) => {
      setState(groupedData.find((g) => g.groupKey === tabKey) || groupedData[0]);
    },
    [groupedData],
  );

  return (
    <div className="flex flex-col gap-2">
      <ContentSelector
        selectedKey={state.groupKey}
        onSelection={onTabSelection}
        items={groupedData.map((gd) => ({ key: gd.groupKey, name: gd.groupName, badge: gd.groups.length }))}
      />
      <ChargeGroupListTable data={state.groups} key={state.groupKey} />
    </div>
  );
}

function ChargeGroupListTable({ data }: { data: IChargeGroup[] }) {
  return (
    <div className="mt-4">
      <Table data={data} />
    </div>
  );
}

function Table({ data }: { data: IChargeGroup[] }) {
  return (
    <div className="relative w-full overflow-x-auto rounded-md border border-transparent shadow dark:border-brand-900">
      <table className="w-full text-left text-sm">
        <thead className="border-b-2 border-brand-100 bg-brand-50 text-xs uppercase dark:border-b dark:border-brand-800 dark:bg-brand-900">
          <tr>
            <th className="w-4/12 px-6 py-3">Name</th>
            <th className="w-2/12 px-6 py-3">Address</th>
            <th className="w-3/12 px-6 py-3">Tenant</th>

            <th className="w-1/12 px-6 py-3">Payment</th>
            <th className="w-1/12 px-6 py-3">Visibility</th>
            <th className="w-1/12 px-6 py-3">
              <span className="sr-only">Actions</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((d, i, a) => (
            <Row chargeGroup={d} key={i} showBottomBorder={i < a.length - 1} />
          ))}

          {data.length === 0 && (
            <tr>
              <td colSpan={13} className="px-6 py-3 text-center">
                No charge groups found.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

function Row({ chargeGroup: d, showBottomBorder }: { chargeGroup: IChargeGroup; showBottomBorder?: boolean }) {
  const { ref, inView } = useInView({
    delay: 100,
  });

  return (
    <tr
      key={d.id}
      ref={ref}
      className={
        "hover:bg-brand-100 dark:border-brand-900 hover:dark:bg-brand-900 " + (showBottomBorder ? "border-b border-brand-100 " : "")
      }
    >
      {!inView ? (
        <td className="block min-h-[50px] px-6 py-3" />
      ) : (
        <>
          <td className="block min-h-[50px] px-6 py-3">
            <div>{d.name}</div>
            <div className="text-xs text-brand-500">{d.description || "N/A"}</div>
          </td>
          <td className="px-6 py-3">
            <div>{d.city}</div>
            <div className="text-xs text-brand-500">{d.address}</div>
          </td>
          <td className="px-6 py-3">{d.tenant}</td>
          <td className="px-6 py-3">
            <div className="flex h-full items-center gap-1">
              <Icon
                path={d.freecharge ? mdiCurrencyUsdOff : mdiCurrencyUsd}
                className={"h-4 w-4 " + (d.freecharge ? "text-green-500" : "text-blue-500")}
              />
              {d.freecharge ? "Free" : "Paid"}
            </div>
          </td>
          <td className="px-6 py-3">
            <div className="flex h-full items-center gap-1">
              <Icon
                path={d.group_type === "PUB" ? mdiLockOpenVariantOutline : mdiLockOutline}
                className={"h-4 w-4 " + (d.group_type === "PUB" ? "text-green-500" : "text-blue-500")}
              />
              {d.group_type === "PUB" ? "Public" : "Private"}
            </div>
          </td>
          <td className=" px-6 py-3 text-right">
            <div className="flex items-center justify-center gap-4">
              <Link
                to={"/chargeGroup/" + d.id}
                className="flex items-center gap-1 font-medium text-ngen hover:cursor-pointer hover:underline"
              >
                <Icon path={mdiOpenInApp} className="-mb-0.5 h-4 w-4" />
                View
              </Link>
            </div>
          </td>
        </>
      )}
    </tr>
  );
}
