import AboutCommunicator from "./aboutCommunicator/AboutCommunicator";
import AboutFirmware from "./aboutFirmware/aboutFirmware";
import AboutPowerbank from "./aboutPowerbank/AboutPowerbank";
import Icon from "@mdi/react";
import { AboutInverter } from "./aboutInverter/aboutInverter";
import { AboutPartner } from "./aboutPartner/AboutPartner";
import { DeviceStatusInfo } from "./deviceStatus/DeviceStatus";
import { getDeviceCustomerAndMeasurementPoint } from "../../../deviceList/_common/utils";
import { IDevice } from "../../../../types/Device";
import { Link } from "react-router-dom";
import { mdiArrowDown, mdiArrowUp, mdiOpenInNew, mdiSwapVertical } from "@mdi/js";
import { NTooltip } from "../../_common/StatusValue";
import { useUser } from "../../../../layout/userRestrictedArea/UserRestrictedArea";
import { Value } from "./_shared/Value";

export default function CustomerData({ device }: { device: IDevice }) {
  const { user } = useUser();
  const isSuperAdmin = user?.permissions.isSuperAdmin || false;

  const { name, mp } = getDeviceCustomerAndMeasurementPoint(device);

  return (
    <div className="flex flex-col gap-8">
      <div className={`break-words`}>
        <div className="mb-2 text-xl font-semibold">{name}</div>

        <div className="flex flex-col gap-2">
          {isSuperAdmin && device.measuring_point.odoo_id != null && (
            <Link
              target="_blank"
              rel="noopener noreferrer"
              to={`http://ps.ngen.si/web#id=${device.measuring_point.odoo_id}&action=999&model=measuring_points.measuring_points_model&view_type=form&cids=1&menu_id=694`}
              className="flex items-center gap-1 font-medium text-ngen hover:cursor-pointer hover:underline"
            >
              <Icon path={mdiOpenInNew} className="-mb-0.5 h-4 w-4" />
              Open within Odoo
            </Link>
          )}

          <Value label="Address" value={device.measuring_point.address || "N/A"} />
          <Value
            label="City"
            value={[device.measuring_point.zipcode || null, device.measuring_point.city || null].filter((t) => !!t).join(" ") || "N/A"}
          />
          <Value label="Measuring point" value={mp} />
          <Value label="Supply start date" value={<SupplyValue contract={device.contract} />} />
          <Value label="Supply group" value={device.supply_group || "N/A"} />

          <DeviceStatusInfo />
        </div>
      </div>
      <AboutPartner device={device} />
      <AboutCommunicator device={device} />

      {device.device.type === "ngenstar" && (
        <>
          <AboutInverter />
          <AboutFirmware />
        </>
      )}

      {device.device.type === "powerbank" && <AboutPowerbank />}
    </div>
  );
}

export function SupplyValue({ contract }: { contract: IDevice["contract"] }) {
  if (!contract || !contract.supply_start_date) {
    return <>N/A</>;
  }

  const { contract_type_id, contract_type_description, supply_start_date, receive_start_date } = contract;

  if (["2", "3"].includes(contract_type_id || "")) {
    return (
      <NTooltip text={contract_type_description}>
        <div className="flex items-center">
          <Icon path={mdiSwapVertical} className="h-4 w-4" />
          {supply_start_date}
        </div>
      </NTooltip>
    );
  } else {
    const items = [];

    if (supply_start_date) {
      items.push({
        tooltip: contract_type_description + ", pričetek dobave",
        element: (
          <div key="supply" className="flex items-center">
            <Icon path={mdiArrowUp} className="h-4 w-4" />
            {supply_start_date}
          </div>
        ),
      });
    }

    if (receive_start_date) {
      items.push({
        tooltip: contract_type_description + ", pričetek odkupa",
        element: (
          <div key="receive" className="flex items-center">
            <Icon path={mdiArrowDown} className="h-4 w-4" />
            {receive_start_date}
          </div>
        ),
      });
    }

    return (
      <div className="flex gap-4">
        {items.map(({ tooltip, element }, index) => (
          <NTooltip key={index} text={tooltip}>
            {element}
          </NTooltip>
        ))}
      </div>
    );
  }
}
