import Icon from "@mdi/react";
import { Fragment, useState } from "react";
import { Listbox as HListbox, Transition } from "@headlessui/react";
import { mdiCheck, mdiChevronDown } from "@mdi/js";

type IItem = {
  key: string;
  name: string;
  value: any;
};

export function Listbox(props: { label: string; items: IItem[]; defaultSelectedItem: IItem; onChange: (item: IItem) => void }) {
  const [selectedItem, setSelectedItem] = useState(props.defaultSelectedItem);

  const filteredItems = props.items;

  const onSelect = (item: IItem) => {
    setSelectedItem(item);
    props.onChange(item);
  };

  return (
    <div className=" w-full">
      <HListbox value={selectedItem} by="key" onChange={onSelect}>
        <HListbox.Label className="text-sm">{props.label}</HListbox.Label>
        <div className="relative z-10 mt-1 flex rounded-md shadow">
          <HListbox.Button className="relative w-full cursor-default rounded-md bg-brand-100 py-2 pl-3 pr-10 text-left focus:outline-none   focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 dark:bg-brand-900 sm:text-sm">
            <span className="block truncate">{selectedItem.name}</span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <Icon path={mdiChevronDown} className="h-5 w-5 text-brand-500" aria-hidden="true" />
            </span>
          </HListbox.Button>

          <Transition
            as={Fragment}
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <HListbox.Options className="absolute mt-10 max-h-60 w-full overflow-auto rounded-md bg-brand-100 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-brand-900 sm:text-sm">
              {filteredItems.length === 0 ? (
                <div className="relative cursor-default select-none px-4 py-2">Nothing found.</div>
              ) : (
                filteredItems.map((item) => (
                  <HListbox.Option
                    key={item.key}
                    className={({ active }) =>
                      `relative cursor-default select-none rounded py-2 pl-10 pr-4 ${active ? "bg-brand-200 dark:bg-brand-800" : ""}`
                    }
                    value={item}
                  >
                    {({ active, selected }) => {
                      return (
                        <>
                          <span className={`block truncate ${selected ? "font-medium" : "font-normal"}`}>
                            {item.name} ({item.value.devices?.length})
                          </span>
                          {selected ? (
                            <span
                              className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? "text-white" : "text-green-600"}`}
                            >
                              <Icon path={mdiCheck} className="h-5 w-5 text-brand-500" aria-hidden="true" />
                            </span>
                          ) : null}
                        </>
                      );
                    }}
                  </HListbox.Option>
                ))
              )}
            </HListbox.Options>
          </Transition>
        </div>
      </HListbox>
    </div>
  );
}
