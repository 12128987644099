import Container from "../../../../../../decorators/container/Container";
import Icon from "@mdi/react";
import { Fragment } from "react";
import { IDeviceDef } from "../historySummaryStats/utils";
import { numberToFixed } from "../../../../../../utils/utils";

export function PercentageStats(props: { title: string; items: IDeviceDef[] }) {
  return (
    <>
      <Container title={props.title} variant="small" isCollapseable>
        <div className="rounded-md border border-brand-200 bg-brand-100 p-2 shadow-sm dark:border-brand-800 dark:bg-brand-900">
          <div className="flex gap-4">
            {props.items.map((item, i, a) => (
              <Fragment key={item.key}>
                <div className="flex items-center gap-1">
                  <Icon path={item.icon} className={"-mb-1 h-4 w-4 " + item.className} />
                  <span>
                    {item.label}: <span className="font-semibold tracking-tight">{numberToFixed(item.value, 0)}</span>
                    <span className="ml-1 text-sm text-brand-700 dark:text-brand-300">%</span>
                  </span>
                </div>
                {i !== a.length - 1 && <div className="text-brand-300">|</div>}
              </Fragment>
            ))}
          </div>
          <div className="mb-1 mt-2 flex w-full overflow-hidden rounded border border-brand-200 bg-brand-100 shadow-sm dark:border-brand-700 dark:bg-brand-900">
            {props.items.map((item) => (
              <div key={item.key} className={"h-3 " + item.bgClassName} style={{ width: item.value + "%" }} />
            ))}
          </div>
        </div>
      </Container>
    </>
  );
}
