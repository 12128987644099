import Icon from "@mdi/react";
import {
  mdiArrowLeft,
  mdiArrowRight,
  mdiBattery70,
  mdiHomeModern,
  mdiHydroPower,
  mdiSigma,
  mdiSolarPanel,
  mdiTransmissionTower,
  mdiWallFire,
  mdiWindTurbine,
} from "@mdi/js";
import { numberToFixed } from "../../../../../../utils/utils";
import { IDevicePlantType } from "../../../../../../types/Device";

export type IPossibleIO = "grid" | "battery" | "photovoltaic" | "load";
export type IEnergyIO = {
  [key in IPossibleIO]: {
    [valKey in Exclude<IPossibleIO, key>]: {
      energy: number;
      percent: number;
    };
  };
};

type IPointType = "source" | "destination" | "total";

export type IDeviceDef = {
  key: string;
  className: string;
  bgClassName: string;
  label: string;
  value: number;
  icon: string;
  unit: string;
  dir?: IPointType;
};

export function getDeviceTypeDef(props: {
  type: IPossibleIO;
  val: number;
  pointType?: IPointType;
  plantType?: IDevicePlantType;
}): IDeviceDef {
  switch (props.type) {
    case "photovoltaic":
      switch (props.plantType) {
        case "wind":
          return {
            key: "photovoltaic",
            className: "text-yellow-600 dark:text-yellow-500",
            bgClassName: "bg-yellow-500",
            label: props.pointType === "source" ? "Energy from wind" : props.pointType === "destination" ? "Energy to wind" : "Wind energy",
            value: props.val,
            icon: mdiWindTurbine,
            unit: "kWh",
            dir: props.pointType,
          };
        case "hydroelectric":
          return {
            key: "photovoltaic",
            className: "text-yellow-600 dark:text-yellow-500",
            bgClassName: "bg-yellow-500",
            label:
              props.pointType === "source" ? "Energy from water" : props.pointType === "destination" ? "Energy to water" : "Water energy",
            value: props.val,
            icon: mdiHydroPower,
            unit: "kWh",
            dir: props.pointType,
          };
        case "biomass":
          return {
            key: "photovoltaic",
            className: "text-yellow-600 dark:text-yellow-500",
            bgClassName: "bg-yellow-500",
            label:
              props.pointType === "source"
                ? "Energy from biomass"
                : props.pointType === "destination"
                  ? "Energy to biomass"
                  : "Biomass energy",
            value: props.val,
            icon: mdiWallFire,
            unit: "kWh",
            dir: props.pointType,
          };
        default:
          return {
            key: "photovoltaic",
            className: "text-yellow-600 dark:text-yellow-500",
            bgClassName: "bg-yellow-500",
            label:
              props.pointType === "source" ? "Energy from solar" : props.pointType === "destination" ? "Energy to solar" : "Solar energy",
            value: props.val,
            icon: mdiSolarPanel,
            unit: "kWh",
            dir: props.pointType,
          };
      }
    case "battery":
      return {
        key: "battery",
        className: "text-green-600 dark:text-green-500",
        bgClassName: "bg-green-500",
        label:
          props.pointType === "source" ? "Energy from storage" : props.pointType === "destination" ? "Energy to storage" : "Storage energy",
        value: props.val,
        icon: mdiBattery70,
        unit: "kWh",
        dir: props.pointType,
      };
    case "grid":
      return {
        key: "grid",
        className: "text-gray-500 dark:text-gray-400",
        bgClassName: "bg-gray-500",
        label: props.pointType === "source" ? "Energy from grid" : props.pointType === "destination" ? "Energy to grid" : "Grid energy",
        value: props.val,
        icon: mdiTransmissionTower,
        unit: "kWh",
        dir: props.pointType,
      };
    case "load":
      return {
        key: "load",
        className: "text-blue-600 dark:text-blue-400",
        bgClassName: "bg-blue-500",
        label: props.pointType === "source" ? "Energy from home" : props.pointType === "destination" ? "Energy to home" : "Home energy",
        value: props.val,
        icon: mdiHomeModern,
        unit: "kWh",
        dir: props.pointType,
      };
  }
}

export function Measures(props: { items: IDeviceDef[] }) {
  return (
    <div className={`flex flex-wrap gap-2`}>
      {props.items.map((stat, statIdx) => (
        <div
          key={statIdx}
          className={`flex flex-grow  basis-52 items-center gap-2 ${stat.className} rounded-md border border-brand-200 bg-brand-100 p-2 shadow-sm dark:border-brand-800 dark:bg-brand-900`}
        >
          <div className="relative">
            <Icon path={stat.icon} className="mx-1 h-6 w-6" />
            {stat.dir && (
              <div className="absolute bottom-0 right-0 -mb-1 -mr-1">
                <Icon
                  path={getItemIconDir(stat.dir)}
                  className="mx-1 h-4 w-4 rounded-full bg-brand-50 bg-opacity-90 text-brand-800 backdrop-blur dark:bg-brand-900 dark:text-brand-300"
                />
              </div>
            )}
          </div>
          <div>
            <div className="text-xl font-semibold tracking-tight">
              {numberToFixed(stat.value, 1)} <span className="text-base">{stat.unit}</span>
            </div>
            <div className="-mt-1 text-sm">{stat.label}</div>
          </div>
        </div>
      ))}
    </div>
  );
}

function getItemIconDir(dir: IPointType) {
  switch (dir) {
    case "source":
      return mdiArrowRight;
    case "destination":
      return mdiArrowLeft;
    case "total":
      return mdiSigma;
  }
}
