import moment from "moment-timezone";
import StatusContainer from "../../../../../_common/StatusContainer";
import StatusValue from "../../../../../_common/StatusValue";
import { getBatteryIcon } from "../../ngenStar/batteryState/BatteryState";
import { getStateContainerClass } from "../../utils";
import { mdiAlarmLightOutline, mdiAlertOutline, mdiCogOutline, mdiInformationBoxOutline, mdiSpeedometer } from "@mdi/js";
import { useId, useState } from "react";
import { useMqttProperty } from "../../../../../../../hooks/useMqttClient";

type IOpData = {
  ac_w_tot: number;
  alarms: number;
  batt_soc_avg: number;
  build_ac_w: number;
  m_ts: string;
  meter_ac_w: number;
  srv_type: number;
  sys_status: number;
  warnings: number;
};

export default function OpStatePwrBank() {
  const componentId = useId();

  const [state, setState] = useState<{ isLoading: boolean; dateTime: string; data: IOpData | undefined }>({
    isLoading: true,
    data: undefined,
    dateTime: "N/A",
  });

  useMqttProperty("status/op", componentId, 0, (data: IOpData) => {
    setState({
      isLoading: false,
      data,
      dateTime: moment.tz(data.m_ts, "Europe/Ljubljana").format("DD.MM.yyyy HH:mm:ss"),
    });
  });

  return (
    <StatusContainer
      title="OP state"
      dateTime={state.dateTime}
      icon={mdiInformationBoxOutline}
      className={getStateContainerClass("inverter")}
    >
      <StatusValue icon={mdiSpeedometer} isLoading={state.isLoading} description="AC TOT" value={state.data?.ac_w_tot} unit="W" />
      <StatusValue icon={mdiSpeedometer} isLoading={state.isLoading} description="METER AC" value={state.data?.meter_ac_w} unit="W" />
      <StatusValue icon={mdiSpeedometer} isLoading={state.isLoading} description="BUILD AC" value={state.data?.build_ac_w} unit="W" />
      <StatusValue
        icon={getBatteryIcon(state.data?.batt_soc_avg)}
        isLoading={state.isLoading}
        description="SoC"
        value={state.data?.batt_soc_avg}
        unit="%"
      />
      <StatusValue icon={mdiAlarmLightOutline} isLoading={state.isLoading} description="ALARMS" value={state.data?.alarms} unit="#" />
      <StatusValue icon={mdiAlertOutline} isLoading={state.isLoading} description="WARNINGS" value={state.data?.warnings} unit="#" />
      <StatusValue icon={mdiInformationBoxOutline} isLoading={state.isLoading} description="SYS STATUS" value={state.data?.sys_status} />
      <StatusValue icon={mdiCogOutline} isLoading={state.isLoading} description="SRV TYPE" value={state.data?.srv_type} />
    </StatusContainer>
  );
}
