import MegapackImgSrc from "../assets/megapack.png";
import NgenStarImgSrc from "../assets/ngenstar.png";
import PowerBankImgSrc from "../assets/powerbank.png";
import SmartMeterImgSrc from "../assets/smartmeter.png";
import AlbaniaVideoSrc from "../flags/albania.webm";
import AustriaVideoSrc from "../flags/austria.webm";
import BelarusVideoSrc from "../flags/belarus.webm";
import BelgiumVideoSrc from "../flags/belgium.webm";
import BosniaAndHerzegovinaVideoSrc from "../flags/bosnia_and_herzegovina.webm";
import BulgariaVideoSrc from "../flags/bulgaria.webm";
import CroatiaVideoSrc from "../flags/croatia.webm";
import CyprusVideoSrc from "../flags/cyprus.webm";
import CzechVideoSrc from "../flags/czech.webm";
import DenmarkVideoSrc from "../flags/denmark.webm";
import EstoniaVideoSrc from "../flags/estonia.webm";
import FinlandVideoSrc from "../flags/finland.webm";
import FranceVideoSrc from "../flags/france.webm";
import GermanyVideoSrc from "../flags/germany.webm";
import GreeceVideoSrc from "../flags/greece.webm";
import HungaryVideoSrc from "../flags/hungary.webm";
import IsraelVideoSrc from "../flags/israel.webm";
import ItalyVideoSrc from "../flags/italy.webm";
import KosovoVideoSrc from "../flags/kosovo.webm";
import LatviaVideoSrc from "../flags/latvia.webm";
import MacedoniaVideoSrc from "../flags/macedonia.webm";
import MoldovaVideoSrc from "../flags/moldova.webm";
import MontenegroVideoSrc from "../flags/montenegro.webm";
import NetherlandsVideoSrc from "../flags/netherlands.webm";
import NorwayVideoSrc from "../flags/norway.webm";
import PolandVideoSrc from "../flags/poland.webm";
import PortugalVideoSrc from "../flags/portugal.webm";
import RomaniaVideoSrc from "../flags/romania.webm";
import RussiaVideoSrc from "../flags/russia.webm";
import SerbiaVideoSrc from "../flags/serbia.webm";
import SlovakiaVideoSrc from "../flags/slovakia.webm";
import SloveniaVideoSrc from "../flags/slovenia.webm";
import SpainVideoSrc from "../flags/spain.webm";
import SwedenVideoSrc from "../flags/sweden.webm";
import SwitzerlandVideoSrc from "../flags/switzerland.webm";
import TurkeyVideoSrc from "../flags/turkey.webm";
import UkraineVideoSrc from "../flags/ukraine.webm";
import UnitedKingdomVideoSrc from "../flags/united_kingdom.webm";
import { IFlagSrc } from "../types/types";

export const REFRESH_TIMEOUT = 15_000;
export const NOTIFICATION_TIMEOUT = 3_000;

export const countries: Record<string, IFlagSrc> = {
  AL: { video: AlbaniaVideoSrc },
  AT: { video: AustriaVideoSrc },
  BY: { video: BelarusVideoSrc },
  BE: { video: BelgiumVideoSrc },
  BA: { video: BosniaAndHerzegovinaVideoSrc },
  BG: { video: BulgariaVideoSrc },
  HR: { video: CroatiaVideoSrc },
  CY: { video: CyprusVideoSrc },
  CZ: { video: CzechVideoSrc },
  DK: { video: DenmarkVideoSrc },
  EE: { video: EstoniaVideoSrc },
  FI: { video: FinlandVideoSrc },
  FR: { video: FranceVideoSrc },
  DE: { video: GermanyVideoSrc },
  GR: { video: GreeceVideoSrc },
  HU: { video: HungaryVideoSrc },
  IL: { video: IsraelVideoSrc },
  IT: { video: ItalyVideoSrc },
  XK: { video: KosovoVideoSrc },
  LV: { video: LatviaVideoSrc },
  MD: { video: MoldovaVideoSrc },
  ME: { video: MontenegroVideoSrc },
  NL: { video: NetherlandsVideoSrc },
  MK: { video: MacedoniaVideoSrc },
  NO: { video: NorwayVideoSrc },
  PL: { video: PolandVideoSrc },
  PT: { video: PortugalVideoSrc },
  RO: { video: RomaniaVideoSrc },
  RU: { video: RussiaVideoSrc },
  RS: { video: SerbiaVideoSrc },
  SK: { video: SlovakiaVideoSrc },
  SI: { video: SloveniaVideoSrc },
  ES: { video: SpainVideoSrc },
  SE: { video: SwedenVideoSrc },
  CH: { video: SwitzerlandVideoSrc },
  TR: { video: TurkeyVideoSrc },
  UA: { video: UkraineVideoSrc },
  GB: { video: UnitedKingdomVideoSrc },
} as const;

export const deviceImages: Record<string, string> = {
  ngenstar: NgenStarImgSrc,
  powerbank: PowerBankImgSrc,
  "smart-meter": SmartMeterImgSrc,
  megapack: MegapackImgSrc,
};
