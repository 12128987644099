import { ButtonHTMLAttributes, forwardRef } from "react";
import { clsx } from "../../../../../utils/clsx";
import Icon from "@mdi/react";

export type ButtonProps = {
  icon?: string;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const Button = forwardRef<HTMLButtonElement, ButtonProps>(({ className, icon, children, ...props }, ref) => {
  return (
    <button
      className={clsx(
        `inline-flex h-9 items-center justify-center gap-x-1 whitespace-nowrap rounded-md border border-brand-600/20 bg-brand-200 px-3 py-2 text-sm font-semibold text-brand-600 shadow-sm transition-colors hover:text-ngen focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-ngen disabled:pointer-events-none disabled:opacity-50 dark:border-brand-500/50 dark:bg-brand-900 dark:text-brand-100 dark:hover:text-ngen`,
        className,
      )}
      ref={ref}
      {...props}
    >
      {icon && <Icon path={icon} className="h-4 w-4" />}
      {children}
    </button>
  );
});

Button.displayName = "Button";

export { Button };
