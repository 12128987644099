import { DarkModeToggle } from "../darkModeToggle/DarkModeToggle";
import Logo from "./logo/Logo";
import Searchbar from "./searchbar/Searchbar";
import UserSettings from "./userSettings/UserSettings";

export default function Topbar() {
  return (
    <>
      <div className="sticky top-0 z-50 flex items-center justify-between gap-2 bg-brand-100 p-3 shadow-md dark:bg-brand-900 sm:px-8">
        <Logo />
        <Searchbar />
        <UserSettings />
      </div>
      <div className="fixed top-14 z-10 flex w-full items-end justify-end p-2 sm:px-8" title="Toggle dark mode">
        <DarkModeToggle />
      </div>
    </>
  );
}
