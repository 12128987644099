import Container from "../../../decorators/container/Container";
import { mdiTabletCellphone } from "@mdi/js";

export function ChargeGroupEnergyScheme() {
  return (
    <div className="w-full lg:max-w-md ">
      <Container title="NGEN App view" icon={mdiTabletCellphone} className="sticky top-20">
        ...
      </Container>
    </div>
  );
}
