import Container from "../../decorators/container/Container";
import { ChangeGroupDetails } from "./ChargeGroupDetails/ChargeGroupDetails";
import { ChangeGroupMeta } from "./ChargeGroupMeta/ChargeGroupMeta";
import { ChargeGroupEnergyScheme } from "./ChargeGroupEnergyScheme/ChargeGroupEnergyScheme";
import { IChargeGroup } from "../../types/ChargeGroup";
import { Loading } from "../../decorators/loading/Loading";
import { RestEnv } from "../../services/restService/restUtils";
import { useCallback } from "react";
import { useGetData } from "../../hooks/useGetData";
import { useParams } from "react-router-dom";

export function ChargeGroup() {
  const { id: chargeGroupId } = useParams();

  const fetch = useGetData<IChargeGroup>({
    request: {
      env: RestEnv.DATA,
      path: `/justcharge/api/v1/groups/id/${chargeGroupId}/`,
    },
    mapData: useCallback((data: any) => data.data, []),
  });

  if (fetch.isLoading) {
    return (
      <div className="w-full max-w-7xl p-2">
        <Loading text="Fetching charge group ..." iconPosition="top" />
      </div>
    );
  }

  if (!fetch.data) {
    return (
      <div className="w-full max-w-7xl p-2">
        <div>Charge group not found</div>
      </div>
    );
  } else {
    return <ChargeGroupContent chargeGroup={fetch.data} />;
  }
}

function ChargeGroupContent({ chargeGroup }: { chargeGroup: IChargeGroup }) {
  const connected = true;
  return (
    <div className="flex w-full flex-col lg:flex-row">
      <div className="w-full border-brand-100 px-8 py-4 dark:border-brand-900 lg:max-w-xs lg:border-r-2 lg:dark:border-r">
        <ChangeGroupMeta chargeGroup={chargeGroup} />
      </div>
      <div className="w-full px-8 py-4">
        {connected ? (
          <div className="flex flex-col-reverse gap-8 lg:flex-row">
            <ChangeGroupDetails chargeGroup={chargeGroup} />
            <ChargeGroupEnergyScheme />
          </div>
        ) : (
          <Container title="Connecting to device ...">
            <Loading text="Connecting to device, please wait ..." iconPosition="top" />
          </Container>
        )}
      </div>
    </div>
  );
}
