import ComboBoxInput from "../../../../components/inputs/comboBoxInput/ComboBoxInput";
import Icon from "@mdi/react";
import TextInput from "../../../../components/inputs/textInput/TextInput";
import { Dialog, DialogPanel, Transition, TransitionChild } from "@headlessui/react";
import { forwardRef, Fragment, useCallback, useImperativeHandle, useState } from "react";
import { LoadingIcon } from "../../../../decorators/loading/Loading";
import { mdiAlertOutline, mdiClose, mdiUpdate } from "@mdi/js";
import { RegisterOptions, useForm } from "react-hook-form";
import { useMqttCommand } from "../../../../hooks/useMqttClient";

export type IInputDefinition = {
  dataKey: string;
  dataLabel: string;
  dataValue: string | number | undefined | null;
  type: "textInput" | "numberInput" | "comboBoxInput";
  options?: { key: string; value: string | number }[];
  unit?: string;
  warning?: string;
  validation?: RegisterOptions;
};

type IProps = {};

export type IEditStateDialog = {
  showDialog: (title: string, input: IInputDefinition) => void;
};

export const EditStateDialog = forwardRef<IEditStateDialog, IProps>(function _EditStateDialog(props, ref) {
  useImperativeHandle(ref, () => ({ showDialog }));
  const command = useMqttCommand();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [state, setState] = useState<{
    isOpen: boolean;
    title: string;
    inputDefinition: undefined | IInputDefinition;
  }>({
    isOpen: false,
    title: "",
    inputDefinition: undefined,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm<{ value: string | number }>({ criteriaMode: "firstError" });

  const showDialog = useCallback(
    (dialogTitle: string, input: IInputDefinition) => {
      reset();

      if (input.dataValue != null) {
        setValue("value", input.dataValue);
      }

      setState({
        title: dialogTitle,
        isOpen: true,
        inputDefinition: input,
      });
    },
    [reset, setValue],
  );

  const closeDialog = useCallback(() => {
    setState((prevState) => ({ ...prevState, isOpen: false }));
    setIsLoading(false);
  }, []);

  const validateFormData = useCallback(() => {
    handleSubmit((formData) => {
      if (state.inputDefinition) {
        command?.(state.inputDefinition?.dataKey, formData.value);
      }
      closeDialog();
    })();
  }, [handleSubmit, command, state.inputDefinition, closeDialog]);

  return (
    <Transition show={state.isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={closeDialog}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-brand-500 bg-opacity-50  transition-opacity dark:bg-brand-900 dark:bg-opacity-50" />
        </TransitionChild>

        <div className="fixed inset-0 z-10 overflow-y-auto  text-brand-950 dark:text-brand-100">
          <div className="flex min-h-full items-end justify-center p-2 text-center sm:items-center sm:p-0">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel className="relative transform overflow-hidden rounded-lg bg-brand-50 px-4 pb-4 pt-5 text-left shadow-xl transition-all dark:bg-brand-950 sm:my-8 sm:w-full sm:max-w-md sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md text-brand-500 hover:text-brand-700 focus:outline-none  "
                    onClick={closeDialog}
                  >
                    <span className="sr-only">Close</span>
                    <Icon path={mdiClose} className="h-6 w-6" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 w-full text-center sm:mt-0 sm:text-left">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6">
                      {state.title}
                    </Dialog.Title>

                    <div className="mt-2">
                      <p className="mb-4 text-sm text-brand-700 dark:text-brand-300">Set a new value and update. Be careful!</p>

                      {state.inputDefinition?.warning && (
                        <div className="mb-4 flex items-center gap-3 rounded bg-orange-600/5 px-4 py-2 text-sm text-orange-600">
                          <span>
                            <Icon path={mdiAlertOutline} className="h-6 w-6" />
                          </span>
                          <span>{state.inputDefinition?.warning}</span>
                        </div>
                      )}

                      {state.inputDefinition?.type === "numberInput" && (
                        <TextInput
                          type="number"
                          label={state.inputDefinition.dataLabel}
                          error={errors.value}
                          suffix={state.inputDefinition.unit}
                          min={(state.inputDefinition.validation?.min as any)?.value ?? undefined}
                          max={(state.inputDefinition.validation?.max as any)?.value ?? undefined}
                          register={register("value", {
                            required: "Value is required!",
                            valueAsNumber: true,
                            ...state.inputDefinition.validation,
                          } as RegisterOptions)}
                        />
                      )}
                      {state.inputDefinition?.type === "comboBoxInput" && (
                        <ComboBoxInput
                          label={state.inputDefinition.dataLabel}
                          error={errors.value}
                          options={state.inputDefinition.options || []}
                          register={register("value", { required: "Value is required!" })}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    disabled={isLoading}
                    type="button"
                    className="inline-flex w-full items-center justify-center gap-2 rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto"
                    onClick={validateFormData}
                  >
                    {isLoading ? <LoadingIcon /> : <Icon path={mdiUpdate} className="size-4" />}
                    Update
                  </button>

                  <button
                    type="button"
                    className="mt-3 inline-flex w-full items-center justify-center gap-2 rounded-md bg-brand-700 px-3 py-2 text-sm font-semibold text-brand-50 shadow-sm ring-1 ring-inset ring-brand-700 hover:bg-brand-600 sm:mt-0 sm:w-auto"
                    onClick={closeDialog}
                  >
                    <Icon path={mdiClose} className="size-4" />
                    Cancel
                  </button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
});
