import Container from "../../../decorators/container/Container";
import Icon from "@mdi/react";
import moment from "moment-timezone";
import { clsx } from "../../../utils/clsx";
import { Disclosure } from "@headlessui/react";
import { getStateContainerClass } from "../../device/deviceLayout/deviceDetails/states/utils";
import { IChargeGroup, IChargeGroupStation } from "../../../types/ChargeGroup";
import { Loading } from "../../../decorators/loading/Loading";
import { mdiChevronDown, mdiChevronUp, mdiEvStation } from "@mdi/js";
import { RestEnv } from "../../../services/restService/restUtils";
import { StationContent } from "./StationContent/StationContent";
import { StationStatus } from "./StationStatus/StationStatus";
import { useCallback } from "react";
import { useGetData } from "../../../hooks/useGetData";

type IResponse = {
  status: string;
  data: IChargeGroupStation[];
};

export function ChargeGroupStations(props: { chargeGroup: IChargeGroup }) {
  const fetch = useGetData<IChargeGroupStation[]>({
    request: {
      env: RestEnv.DATA,
      path: `/justcharge/api/v1/stations/group/id/${props.chargeGroup.id}/`,
    },
    mapData: useCallback(
      (data: IResponse) =>
        data.data.sort((a, b) => {
          return ((a.is_removed ? 1 : 0) - (b.is_removed ? 1 : 0)) * 10 + (a.name || "").localeCompare(b.name || "");
        }),
      [],
    ),
  });

  if (fetch.isLoading) {
    return (
      <Container title="Fetching stations ...">
        <Loading text="Fetching stations, please wait ..." iconPosition="top" />
      </Container>
    );
  }

  return (
    <Container title="Stations" icon={mdiEvStation} className={getStateContainerClass(undefined)}>
      <div className="flex flex-col gap-2">
        {(fetch.data || []).map((station) => (
          <Disclosure as="div" key={station.id} className="rounded-lg bg-brand-500/5 p-4 dark:bg-brand-500/5">
            {({ open }) => (
              <>
                <Disclosure.Button className={clsx("flex w-full items-start justify-between border-b border-brand-500/10 pb-2 text-left")}>
                  <span className="flex items-center justify-start gap-2 text-base font-semibold leading-7">
                    <div
                      className={clsx(
                        station.is_removed ? "text-brand-600 line-through dark:text-brand-500" : "text-brand-950 dark:text-brand-50",
                      )}
                    >
                      {station.name}
                    </div>
                    <div>
                      <StationStatus status={station.status || "N/A"} />
                    </div>
                  </span>

                  <span className="ml-6 flex h-7 items-center gap-1">
                    {station.last_seen && (
                      <span className="text-sm text-brand-500">
                        Last seen: {moment.tz(station.last_seen, "Europe/Ljubljana").format("DD.MM.yyyy HH:mm:ss")}
                      </span>
                    )}
                    <Icon path={open ? mdiChevronUp : mdiChevronDown} className="h-6 w-6" />
                  </span>
                </Disclosure.Button>

                <Disclosure.Panel as="div" className="mt-2">
                  <StationContent station={station} />
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        ))}
        {(fetch.data || []).length === 0 && <div>No stations available ...</div>}
      </div>
    </Container>
  );
}
