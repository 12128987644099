import { createContext, useCallback, useContext, useEffect, useRef } from "react";
import { useUser } from "../layout/userRestrictedArea/UserRestrictedArea";
import { ConnectionStates, useMqttConnection } from "../layout/mqttConnectionProvider/MQTTConnectionProvider";

export function useMqttClient(deviceType: "ngenstar" | "powerbank", device: string) {
  const topics = useRef<{
    [topicKey: string]: {
      [consumerKey: string]: {
        dataReceivedTs: number;
        dataQueryTs: number;
        dataTimeoutMs: number;
        callback: (data: any) => void;
      };
    };
  }>({});

  const commandTopics = useRef<{
    [topicKey: string]: {
      [consumerKey: string]: {
        callback: (data: any) => void;
      };
    };
  }>({});

  const user = useUser();
  const ALL_MQTT_CONNETION = useMqttConnection();

  const stateBase = deviceType === "powerbank" ? "" : "state";
  const topicBase = deviceType === "powerbank" ? `pixii/mc/${device}` : `ngen/${device}/`;
  const client = deviceType === "powerbank" ? ALL_MQTT_CONNETION.powerbankService.client : ALL_MQTT_CONNETION.ngenstarService.client;
  const clientConnected =
    (deviceType === "powerbank" ? ALL_MQTT_CONNETION.powerbankState : ALL_MQTT_CONNETION.ngenstarState) === ConnectionStates.Connected;

  useEffect(() => {
    client?.on("message", (messageTopic, messageBody) => {
      const parsedData = JSON.parse(messageBody.toString());

      switch (deviceType) {
        case "ngenstar":
          {
            const cleanedTopic = messageTopic.replace(topicBase, "");
            const [topicType, topic] = cleanedTopic.split("/");

            switch (topicType) {
              case "state":
                if (topics.current[topic]) {
                  Object.values(topics.current[topic]).forEach((eFunction) => {
                    eFunction.dataReceivedTs = Date.now();
                    eFunction.callback(parsedData);
                  });
                }

                break;
              case "command":
                if (commandTopics.current[topic]) {
                  Object.values(commandTopics.current[topic]).forEach((eFunction) => {
                    eFunction.callback(parsedData);
                  });
                }

                break;
              default:
                break;
            }
          }
          break;

        default:
          {
            const cleanedTopic = messageTopic.replace(topicBase + "/", "");

            if (topics.current[cleanedTopic]) {
              Object.values(topics.current[cleanedTopic]).forEach((eFunction) => {
                eFunction.dataReceivedTs = Date.now();
                eFunction.callback(parsedData);
              });
            }
          }

          break;
      }
    });

    if (deviceType === "ngenstar") {
      const interval = setInterval(() => {
        if (client?.connected) {
          Object.entries(topics.current).forEach(([topic, consumers]) => {
            Object.entries(consumers).forEach(([consumer, consumerData]) => {
              const now = Date.now();
              if (
                now - consumerData.dataReceivedTs > consumerData.dataTimeoutMs &&
                now - consumerData.dataQueryTs > consumerData.dataTimeoutMs
              ) {
                client.publish(topicBase + "query/" + topic, JSON.stringify({ request: true }), { qos: 1 });
                consumerData.dataQueryTs = Date.now();
              }
            });
          });
        }
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [topicBase, client, deviceType]);

  const subscribe = useCallback(
    (topic: string, consumer: string, timeoutMs: number, onData: (data: any) => void) => {
      if (!topics.current[topic]) {
        topics.current[topic] = {};
      }

      topics.current[topic][consumer] = {
        callback: onData,
        dataReceivedTs: 0,
        dataQueryTs: 0,
        dataTimeoutMs: timeoutMs,
      };

      if (!clientConnected || client?.disconnected || !client?.connected) {
        return;
      }

      client.subscribe(topicBase + stateBase + "/" + topic, { qos: 1 });
      if (deviceType === "ngenstar") {
        client.publish(topicBase + "query/" + topic, JSON.stringify({ request: true }), { qos: 1 });
        topics.current[topic][consumer].dataQueryTs = Date.now();
      }
    },
    [client, topicBase, stateBase, clientConnected, deviceType],
  );

  const subscribeToCommand = useCallback(
    (topic: string, consumer: string, onData: (data: any) => void) => {
      if (!commandTopics.current[topic]) {
        commandTopics.current[topic] = {};
      }

      commandTopics.current[topic][consumer] = {
        callback: onData,
      };

      if (!clientConnected || client?.disconnected || !client?.connected) {
        return;
      }

      client.subscribe(topicBase + "command/" + topic, { qos: 1 });
    },
    [client, topicBase, clientConnected],
  );

  const command = useCallback(
    (topic: string, value: any) => {
      if (client?.connected) {
        client.subscribe(topicBase + "command/" + topic, { qos: 1 });
        client.publish(
          topicBase + "command/" + topic,
          JSON.stringify({ value: value?.toString?.(), platform: "nexus", user: user.user?.email }),
          {
            retain: true,
            qos: 2,
          },
        );
      }
    },
    [client, topicBase, user],
  );

  const unSubscribe = useCallback(
    (topic: string, consumer: string) => {
      delete topics.current[topic][consumer];

      if (Object.keys(topics.current[topic]).length === 0) {
        delete topics.current[topic];
        client?.unsubscribe(topicBase + stateBase + "/" + topic);
      }
    },
    [client, topicBase, stateBase],
  );

  const unSubscribeFromCommand = useCallback(
    (topic: string, consumer: string) => {
      delete commandTopics.current[topic][consumer];

      if (Object.keys(commandTopics.current[topic]).length === 0) {
        delete commandTopics.current[topic];
        client?.unsubscribe(topicBase + "command/" + topic);
      }
    },
    [client, topicBase],
  );

  return { subscribe, unSubscribe, command, subscribeToCommand, unSubscribeFromCommand, connected: clientConnected };
}

export const MQTTData = createContext<{
  subscribe?: (topic: string, consumer: string, timeoutMs: number, onData: (data: any) => void) => void;
  unSubscribe?: (topic: string, consumer: string) => void;
  subscribeToCommand?: (topic: string, consumer: string, onData: (data: any) => void) => void;
  unSubscribeFromCommand?: (topic: string, consumer: string) => void;
  command?: (topic: string, data: any) => void;
}>({});

export function useMqttProperty(topic: string, consumer: string, timeOutMs: number, onData: (data: any) => void) {
  const mqttContext = useContext(MQTTData);
  const callBack = useRef(onData);

  useEffect(() => {
    mqttContext?.subscribe?.(topic, consumer, timeOutMs, callBack.current);

    return () => {
      mqttContext.unSubscribe?.(topic, consumer);
    };
  }, [mqttContext, topic, consumer, timeOutMs]);
}

export function useMqttCommandProperty(topic: string | undefined, consumer: string, onData: (data: any) => void) {
  const mqttContext = useContext(MQTTData);
  const callBack = useRef(onData);

  useEffect(() => {
    if (topic != null) {
      mqttContext?.subscribeToCommand?.(topic, consumer, callBack.current);

      return () => {
        mqttContext?.unSubscribeFromCommand?.(topic, consumer);
      };
    }
  }, [mqttContext, topic, consumer]);
}

export function useMqttCommand() {
  const mqttContext = useContext(MQTTData);
  return mqttContext?.command;
}
