import Container from "../../../decorators/container/Container";
import Icon from "@mdi/react";
import { mdiClockOutline } from "@mdi/js";

export default function StatusContainer(props: {
  title: string;
  icon?: string;
  dateTime?: string;
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <Container
      title={props.title}
      icon={props.icon}
      isCollapseable
      className={props.className}
      titleRightContnent={
        props.dateTime && (
          <div className="flex items-center gap-1 font-mono text-xs font-extralight italic text-brand-500">
            <Icon path={mdiClockOutline} className="h-3 w-3" />
            {props.dateTime}
          </div>
        )
      }
    >
      <div className="grid w-full grid-cols-1 gap-2 sm:grid-cols-2 lg:grid-cols-1 2xl:grid-cols-2 3xl:grid-cols-3 4xl:grid-cols-4">
        {props.children}
      </div>
    </Container>
  );
}
