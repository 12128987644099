import Container from "../../../../../decorators/container/Container";
import Icon from "@mdi/react";
import moment from "moment-timezone";
import { DeviceStatus } from "../../../../deviceList/_common/DeviceStatus";
import { LoadingIcon } from "../../../../../decorators/loading/Loading";
import { mdiClockOutline, mdiSourceBranch, mdiTagOutline } from "@mdi/js";
import { useId, useState } from "react";
import { useMqttProperty } from "../../../../../hooks/useMqttClient";
import { Value } from "../_shared/Value";

type IState = {
  isLoading: boolean;
  data:
    | {
        time: string;
        device: {
          name: string;
          model: string;
          online: boolean;
        };
        gateway: {
          name: string;
          model: string;
          serial: string;
        };
      }
    | undefined;
};

export default function AboutPowerbank() {
  const componentId = useId();

  const [state, setState] = useState<IState>({
    isLoading: true,
    data: undefined,
  });

  useMqttProperty("status/solar", componentId, 0, (data: any) => {
    setState({
      data: {
        time: moment.tz(data.timestamp * 1000, "Europe/Ljubljana").format("DD.MM.yyyy HH:mm:ss"),
        device: {
          name: data.device.name,
          model: data.device.model,
          online: data.device.online,
        },
        gateway: {
          name: data.gateway.name,
          model: data.gateway.model,
          serial: data.gateway.serial,
        },
      },
      isLoading: false,
    });
  });

  return (
    <>
      <Container title="PB Gateway" icon={mdiSourceBranch} isCollapseable>
        <div className="flex flex-col gap-2">
          <Value label="Gateway name" value={state.data?.gateway.name || "N/A"} isLoading={state.isLoading} />
          <Value label="Gateway model" value={state.data?.gateway.model || "N/A"} isLoading={state.isLoading} />
          <Value label="Gateway serial number" value={state.data?.gateway.serial || "N/A"} isLoading={state.isLoading} />

          <div className="flex items-center gap-2 italic">
            <div className="flex items-center gap-1 font-mono text-xs font-extralight italic text-brand-500">
              <Icon path={mdiClockOutline} className="h-3 w-3" />
              {state.data?.time}
            </div>
            {state.isLoading && <LoadingIcon />}
          </div>
        </div>
      </Container>
      <Container title="PB Device" icon={mdiTagOutline} isCollapseable>
        <div className="flex flex-col gap-2">
          <Value
            label="Device status"
            value={<DeviceStatus status={state.data?.device.online ? "yes" : "no"} />}
            isLoading={state.isLoading}
          />
          <Value label="Device name" value={state.data?.device.name || "N/A"} isLoading={state.isLoading} />
          <Value label="Device model" value={state.data?.device.model || "N/A"} isLoading={state.isLoading} />

          <div className="flex items-center gap-2 italic">
            <div className="flex items-center gap-1 font-mono text-xs font-extralight italic text-brand-500">
              <Icon path={mdiClockOutline} className="h-3 w-3" />
              {state.data?.time}
            </div>
            {state.isLoading && <LoadingIcon />}
          </div>
        </div>
      </Container>
    </>
  );
}
