const nccStoragePrefix = "nsi_";

export const PermanentUserPreferences = {
  set: function (source: string, key: string, value: any) {
    localStorage.setItem(`${nccStoragePrefix}_${source}_${key}`, JSON.stringify({ value }));
  },

  get: function (source: string, key: string, coalesceValue?: any): any {
    const existingValueObj = localStorage.getItem(`${nccStoragePrefix}_${source}_${key}`);

    if (existingValueObj) {
      const json = JSON.parse(existingValueObj);
      return json?.value ?? coalesceValue;
    }

    return coalesceValue;
  },

  purge: function (source: string, key: string): void {
    localStorage.removeItem(`${nccStoragePrefix}_${source}_${key}`);
  },
};

export const TemporaryUserPreferences = {
  set: function (source: string, key: string, value: any) {
    sessionStorage.setItem(`${nccStoragePrefix}_${source}_${key}`, JSON.stringify({ value }));
  },

  get: function (source: string, key: string, coalesceValue?: any): any {
    const existingValueObj = sessionStorage.getItem(`${nccStoragePrefix}_${source}_${key}`);

    if (existingValueObj) {
      const json = JSON.parse(existingValueObj);
      return json?.value ?? coalesceValue;
    }

    return coalesceValue;
  },

  purge: function (source: string, key: string): void {
    sessionStorage.removeItem(`${nccStoragePrefix}_${source}_${key}`);
  },
};
