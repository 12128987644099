import { countries } from "./const";
import { format, formatDistanceToNowStrict, isThisMonth, isThisWeek } from "date-fns";
import { type IDevice, devicePlantTypes, deviceTypes } from "../../../types/Device";
import type { IDeviceCount, IResult } from "../types/types";

export const generateDeviceName = (device: string): string => {
  switch (device) {
    case "ngenstar":
      return "NGEN STAR";
    case "powerbank":
      return "POWER BANK";
    case "smart-meter":
      return "SMART METER";
    default:
      return device.split("-").join(" ");
  }
};

export const generateDevicePlantTypeName = (devicePlantType: string): string => {
  switch (devicePlantType) {
    case "none":
      return "Other";
    case "solar":
      return "Solar Power Plant";
    case "hydroelectric":
      return "Hydroelectric Power Plant";
    case "wind":
      return "Wind Power Plant";
    default:
      return `${devicePlantType} Power Plant`;
  }
};

export const generateResult = (data: IDevice[]): IResult => {
  return data.reduce(
    (acc, device) => {
      if (device.measuring_point.country.iso_code === "XY") {
        return acc;
      }

      const iso = device.measuring_point.country.iso_code;
      const name = device.measuring_point.country.name;

      let thisWeek = false;
      let thisMonth = false;

      if (device.start_date) {
        const date = new Date(device.start_date);
        thisWeek = isThisWeek(date);
        thisMonth = isThisMonth(date);
      }

      const [deviceType, devicePlantType] = [device.device.type, device.device.power_plant_type];

      if (!acc.byDevice[deviceType]) {
        acc.byDevice[deviceType] = {
          total: 1,
          thisWeek: thisWeek ? 1 : 0,
          thisMonth: thisMonth ? 1 : 0,
          totalByType: {
            [devicePlantType as string]: 1,
          },
        };
      } else {
        acc.byDevice[deviceType].total++;

        if (thisWeek) {
          acc.byDevice[deviceType].thisWeek++;
        }

        if (thisMonth) {
          acc.byDevice[deviceType].thisMonth++;
        }

        if (!acc.byDevice[deviceType].totalByType?.[devicePlantType as string]) {
          acc.byDevice[deviceType].totalByType = { ...acc.byDevice[deviceType].totalByType, [devicePlantType as string]: 1 };
        } else {
          acc.byDevice[deviceType].totalByType![devicePlantType as string]++;
        }
      }

      if (!acc.byCountry[iso]) {
        acc.byCountry[iso] = {
          name,
          iso,
          total: 1,
          flagSrc: countries[iso] ?? undefined,
          byDevice: {
            ...deviceTypes.reduce(
              (acc, device) => {
                acc[device] = {
                  total: 0,
                  thisWeek: 0,
                  thisMonth: 0,
                  totalByType: {
                    ...devicePlantTypes.reduce(
                      (a, deviceType) => {
                        a[deviceType] = 0;
                        return a;
                      },
                      {} as Record<string, number>,
                    ),
                  },
                };
                return acc;
              },
              {} as Record<
                string,
                IDeviceCount & {
                  totalByType?: Record<string, number>;
                }
              >,
            ),
          },
        };

        if (!acc.byCountry[iso].byDevice[deviceType]) {
          acc.byCountry[iso].byDevice[deviceType] = {
            total: 1,
            thisWeek: thisWeek ? 1 : 0,
            thisMonth: thisMonth ? 1 : 0,
            totalByType: {
              [devicePlantType as string]: 1,
            },
          };
        } else {
          acc.byCountry[iso].byDevice[deviceType].total++;

          if (thisWeek) {
            acc.byCountry[iso].byDevice[deviceType].thisWeek++;
          }

          if (thisMonth) {
            acc.byCountry[iso].byDevice[deviceType].thisMonth++;
          }

          if (!acc.byCountry[iso].byDevice[deviceType].totalByType?.[devicePlantType as string]) {
            acc.byCountry[iso].byDevice[deviceType].totalByType![devicePlantType as string] = 1;
          } else {
            acc.byCountry[iso].byDevice[deviceType].totalByType![devicePlantType as string]++;
          }
        }
      } else {
        acc.byCountry[iso].total++;

        if (!acc.byCountry[iso].byDevice[deviceType]) {
          acc.byCountry[iso].byDevice[deviceType] = {
            total: 1,
            thisWeek: thisWeek ? 1 : 0,
            thisMonth: thisMonth ? 1 : 0,
            totalByType: {
              [devicePlantType as string]: 1,
            },
          };
        } else {
          acc.byCountry[iso].byDevice[deviceType].total++;

          if (thisWeek) {
            acc.byCountry[iso].byDevice[deviceType].thisWeek++;
          }

          if (thisMonth) {
            acc.byCountry[iso].byDevice[deviceType].thisMonth++;
          }

          if (!acc.byCountry[iso].byDevice[deviceType].totalByType?.[devicePlantType as string]) {
            acc.byCountry[iso].byDevice[deviceType].totalByType![devicePlantType as string] = 1;
          } else {
            acc.byCountry[iso].byDevice[deviceType].totalByType![devicePlantType as string]++;
          }
        }
      }

      const uniqueDeviceTypes = [...new Set(Object.keys(acc.byDevice))];
      if (uniqueDeviceTypes.length !== deviceTypes.length) {
        const diff = uniqueDeviceTypes.filter((i) => !deviceTypes.includes(i as any));

        Object.keys(acc.byCountry).forEach((c) => {
          diff.forEach((dt) => {
            if (!acc.byCountry[c].byDevice[dt]) {
              acc.byCountry[c].byDevice[dt] = {
                total: 0,
                thisWeek: 0,
                thisMonth: 0,
                totalByType: {
                  ...devicePlantTypes.reduce(
                    (a, deviceType) => {
                      a[deviceType] = 0;
                      return a;
                    },
                    {} as Record<string, number>,
                  ),
                },
              };
            }
          });
        });
      }

      return acc;
    },
    {
      byDevice: {
        ...deviceTypes.reduce(
          (acc, device) => {
            acc[device] = {
              total: 0,
              thisWeek: 0,
              thisMonth: 0,
              totalByType: {
                ...devicePlantTypes.reduce(
                  (a, deviceType) => {
                    a[deviceType] = 0;
                    return a;
                  },
                  {} as Record<string, number>,
                ),
              },
            };
            return acc;
          },
          {} as Record<
            string,
            IDeviceCount & {
              totalByType?: Record<string, number>;
            }
          >,
        ),
      },
      byCountry: {},
    } as IResult,
  );
};

export const calculateFreshDevices = (old: IResult, fresh: IResult): number => {
  return (
    Object.keys(fresh.byDevice).reduce((acc, device) => acc + fresh.byDevice[device].total, 0) -
    Object.keys(old.byDevice).reduce((acc, device) => acc + old.byDevice[device].total, 0)
  );
};

export const timeAgoHumanized = (date: Date): string => {
  return formatDistanceToNowStrict(date, { addSuffix: true });
};

export const addRandomDevice = (devices: IDevice[]): IDevice[] => {
  const qtyControlDate = format(new Date(), "yyyy-MM-dd HH:mm:ss");
  const deviceType = deviceTypes[getRandInt(0, deviceTypes.length - 1)];
  const devicePlantType = devicePlantTypes[getRandInt(0, devicePlantTypes.length - 1)];

  devices.push({
    device: {
      id: "",
      ip: "",
      type: deviceType,
      power_plant_type: deviceType === "smart-meter" ? undefined : devicePlantType,
    },
    online: "",
    software_version: "",
    meter_serial_number: "",
    last_online: "",
    supply_group: "",
    measuring_point: {
      address: "",
      zipcode: "",
      city: "",
      import_limit: 0,
      export_limit: 0,
      country: {
        name: "Slovenia",
        iso_code: "SI",
      },
      odoo_id: 0,
    },
    start_date: qtyControlDate,
    quality_control_date: qtyControlDate,
    quality_control_performed_by: null,
  });

  return devices;
};

function getRandInt(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1) + min);
}
