import DeviceListHeader from "./DeviceListHeader";
import { DeviceListContent } from "./deviceListContent/DeviceListContent";
import { getDevicesByPartner } from "./deviceListContent/DeviceListContent.utils";
import { getMatchingDevices } from "./_common/utils";
import { IDevice } from "../../types/Device";
import { Listbox } from "./_common/listbox/Listbox";
import { Loading } from "../../decorators/loading/Loading";
import { RestEnv } from "../../services/restService/restUtils";
import { useCallback, useEffect, useState } from "react";
import { useGetData } from "../../hooks/useGetData";
import { useSearchParams } from "react-router-dom";

export function DeviceList() {
  const fetch = useGetData<IDevice[]>({
    request: {
      env: RestEnv.DATA,
      path: "/api/1.0/device/list",
    },
    mapData: useCallback((data: any) => data.data, []),
  });

  return (
    <div className="w-full max-w-7xl p-2">
      {fetch.isLoading ? (
        <Loading text="Fetching devices ..." iconPosition="top" />
      ) : (
        <DeviceListDisplay key={fetch._ts} devices={fetch.data || []} onRefresh={fetch.fetchData} />
      )}
    </div>
  );
}

function DeviceListDisplay(props: { devices: IDevice[]; onRefresh: () => void }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const query = searchParams.get("query");
  const queryPartner = searchParams.get("partner");

  const [state, setState] = useState(
    (() => {
      const matchingDevices = getMatchingDevices(props.devices || [], query);
      const devicesByPartner = getDevicesByPartner(matchingDevices);

      const indexOfPartner = devicesByPartner.findIndex((d) => d.partnerKey === queryPartner);
      const selectedPartner = indexOfPartner > 0 ? devicesByPartner[indexOfPartner] : devicesByPartner[0];

      return {
        devicesByPartner,
        selectedPartner: selectedPartner,
        defaultPartner: selectedPartner,
        key: Date.now(),
        dropdownKey: Date.now(),
      };
    })(),
  );

  useEffect(() => {
    const matchingDevices = getMatchingDevices(props.devices || [], query);
    const devicesByPartner = getDevicesByPartner(matchingDevices);

    const indexOfPartner = devicesByPartner.findIndex((d) => d.partnerKey === queryPartner);
    const selectedPartner = indexOfPartner > 0 ? devicesByPartner[indexOfPartner] : devicesByPartner[0];

    setState({
      devicesByPartner,
      selectedPartner: selectedPartner,
      defaultPartner: selectedPartner,
      key: Date.now(),
      dropdownKey: Date.now(),
    });
  }, [query, queryPartner, props.devices]);

  const setSelectedPartner = useCallback(
    (partner: any) => {
      setSearchParams({ partner: partner.partnerKey, ...(query ? { query } : {}) });
      setState((state) => ({ ...state, selectedPartner: partner, key: Date.now() }));
    },
    [query, setSearchParams],
  );

  const isMultiplePartners = state.devicesByPartner.length > 2; // because of the "All" partner and the default partner

  return (
    <>
      <DeviceListHeader title="Devices" onClearNavigateTo="/device" query={query} onRefresh={props.onRefresh} />
      {isMultiplePartners && (
        <div className="my-4">
          <Listbox
            label="Installed by"
            key={state.dropdownKey}
            items={state.devicesByPartner.map((d) => ({ key: d.partnerKey, name: d.partnerName, value: d }))}
            defaultSelectedItem={{
              key: state.defaultPartner.partnerKey,
              name: state.defaultPartner.partnerName,
              value: state.defaultPartner,
            }}
            onChange={(item) => setSelectedPartner(item.value)}
          />
        </div>
      )}
      <DeviceListContent key={state.key} devices={state.selectedPartner.devices} />
    </>
  );
}
