import { IChargeGroup } from "../../types/ChargeGroup";

const knownCountries = JSON.parse(
  `{
    "status": "success",
    "data": [
      {
        "id": 1,
        "name": "Andorra",
        "short": "AD"
      },
      {
        "id": 2,
        "name": "Albania",
        "short": "AL"
      },
      {
        "id": 3,
        "name": "Austria",
        "short": "AT"
      },
      {
        "id": 4,
        "name": "Bosnia and Herzegovina",
        "short": "BA"
      },
      {
        "id": 5,
        "name": "Belgium",
        "short": "BE"
      },
      {
        "id": 6,
        "name": "Bulgaria",
        "short": "BG"
      },
      {
        "id": 7,
        "name": "Belarus",
        "short": "BY"
      },
      {
        "id": 8,
        "name": "Croatia",
        "short": "HR"
      },
      {
        "id": 9,
        "name": "Czech Republic",
        "short": "CZ"
      },
      {
        "id": 10,
        "name": "Denmark",
        "short": "DK"
      },
      {
        "id": 11,
        "name": "Estonia",
        "short": "EE"
      },
      {
        "id": 12,
        "name": "Finland",
        "short": "FI"
      },
      {
        "id": 13,
        "name": "France",
        "short": "FR"
      },
      {
        "id": 14,
        "name": "Germany",
        "short": "DE"
      },
      {
        "id": 15,
        "name": "Greece",
        "short": "GR"
      },
      {
        "id": 16,
        "name": "Hungary",
        "short": "HU"
      },
      {
        "id": 17,
        "name": "Ireland",
        "short": "IE"
      },
      {
        "id": 18,
        "name": "Iceland",
        "short": "IS"
      },
      {
        "id": 19,
        "name": "Italy",
        "short": "IT"
      },
      {
        "id": 20,
        "name": "Liechtenstein",
        "short": "LI"
      },
      {
        "id": 21,
        "name": "Lithuania",
        "short": "LT"
      },
      {
        "id": 22,
        "name": "Luxembourg",
        "short": "LU"
      },
      {
        "id": 23,
        "name": "Latvia",
        "short": "LV"
      },
      {
        "id": 24,
        "name": "Monaco",
        "short": "MC"
      },
      {
        "id": 25,
        "name": "Moldova",
        "short": "MD"
      },
      {
        "id": 26,
        "name": "Malta",
        "short": "MT"
      },
      {
        "id": 27,
        "name": "Montenegro",
        "short": "ME"
      },
      {
        "id": 28,
        "name": "Netherlands",
        "short": "NL"
      },
      {
        "id": 29,
        "name": "North Macedonia",
        "short": "MK"
      },
      {
        "id": 30,
        "name": "Norway",
        "short": "NO"
      },
      {
        "id": 31,
        "name": "Poland",
        "short": "PL"
      },
      {
        "id": 32,
        "name": "Portugal",
        "short": "PT"
      },
      {
        "id": 33,
        "name": "Romania",
        "short": "RO"
      },
      {
        "id": 34,
        "name": "Russian Federation",
        "short": "RU"
      },
      {
        "id": 35,
        "name": "Sweden",
        "short": "SE"
      },
      {
        "id": 36,
        "name": "Slovenia",
        "short": "SI"
      },
      {
        "id": 37,
        "name": "Slovakia",
        "short": "SK"
      },
      {
        "id": 38,
        "name": "San Marino",
        "short": "SM"
      },
      {
        "id": 39,
        "name": "Switzerland",
        "short": "CH"
      },
      {
        "id": 40,
        "name": "Spain",
        "short": "ES"
      },
      {
        "id": 41,
        "name": "Serbia",
        "short": "RS"
      },
      {
        "id": 42,
        "name": "United Kingdom (Great Britain)",
        "short": "GB"
      },
      {
        "id": 43,
        "name": "Ukraine",
        "short": "UA"
      },
      {
        "id": 44,
        "name": "Vatican City State (Holy See)",
        "short": "VA"
      }
    ]
  }`,
).data.reduce((acc: any, country: any) => {
  acc[country.id] = country;
  return acc;
}, {});

export function getChargeGroupsByCountry(chargeGroups: IChargeGroup[]) {
  const ALL: { groupKey: string; groupName: string; groups: IChargeGroup[] } = {
    groupKey: "all",
    groupName: "All countries",
    groups: chargeGroups,
  };

  const GROUPS = Object.values(
    chargeGroups.reduce(
      (acc, group) => {
        const gCode = group.country || 0;

        if (!acc[gCode]) {
          acc[gCode] = {
            groupKey: gCode?.toString() || "N/A",
            groupName: knownCountries[gCode]?.name || "Unknown",
            groups: [group],
          };
        } else {
          acc[gCode].groups.push(group);
        }

        return acc;
      },
      {} as {
        [countryKey: string]: {
          groupKey: string;
          groupName: string;
          groups: IChargeGroup[];
        };
      },
    ),
  );

  return [ALL, ...GROUPS.sort((a, b) => a.groupName.localeCompare(b.groupName))];
}
