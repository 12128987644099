import logoSrc from "./../../../assets/sgconnect_logo.jpg";
import { Link, useLocation } from "react-router-dom";

export default function Logo() {
  const location = useLocation();
  const itemKey = location.pathname.split("/")[1];
  const linkTo = ["device", "chargeGroup"].includes(itemKey) ? `/${itemKey}` : "/";

  return (
    <Link to={linkTo} className="hover:cursor-pointer">
      <div className="-m-4 flex-col items-center rounded p-1" style={{ background: "black" }}>
        <img src={logoSrc} alt="SG connect logo" className="max-h-8" />
      </div>
    </Link>
  );
}
