import DotSpinner from "./dotSpinner/DotSpinner";
import industryImg from "./assets/Stavba.png";
import LottieAnimation from "./LottieAnimation";
import pb_baterija_stevec from "./assets/pb_baterija_stevec.json";
import pb_omrezje_stevec from "./assets/pb_omrezje_stevec.json";
import pb_solar_animation from "./assets/pb_solar_stevec.json";
import pb_stavba_stevec from "./assets/pb_stavba_stevec.json";
import pb_stecev_stavba from "./assets/pb_stevec_stavba.json";
import pb_stevec_baterija from "./assets/pb_stevec_baterija.json";
import pb_stevec_omrezje from "./assets/pb_stevec_omrezje.json";
import React, { useEffect, useState } from "react";
import { getTimeOfDay, TimeOfDay } from "./helpers/SunCalcModule";
import { useTranslation } from "react-i18next";

type Battery = {
  batt_a: number;
  batt_cap_kwh: number;
  batt_cap_w_ch: number;
  batt_cap_w_disch: number;
  batt_soc: number;
  batt_soc_max: number;
  batt_soc_min: number;
  batt_soh: number;
  batt_status: number;
  batt_temp_max: number;
  batt_temp_min: number;
  batt_v: number;
  batt_w: number;
  m_ts: string;
  num_batt: number;
};
interface AnimationProps {
  pbMeter: any;
  pbSolar: any;
  pbBattery: Battery;
  className?: string;
  opacity?: number;
}
const formatPower = (value: any) => {
  const kWValue = Math.abs(value / 1000).toFixed(1);
  return parseFloat(kWValue) % 1 === 0 ? parseFloat(kWValue).toFixed(0) : kWValue;
};
const extractSolar = (structure: any) => {
  if (structure && structure.device && Array.isArray(structure.device.readings)) {
    for (let reading of structure.device.readings) {
      if (reading.param === "Psum_kW") {
        let solarPower = parseFloat(reading.value) * 1000;
        return parseFloat(formatPower(solarPower));
      }
    }
  }
  return 0;
};
const sumEnergy = (solar: any, battery: any, grid: any) => {
  let solarEnergy = extractSolar(solar) * 1000 + -1 * battery + grid;
  return formatPower(solarEnergy);
};
const AnimationPowerPlant: React.FC<AnimationProps> = ({ pbMeter, pbBattery, pbSolar, className, opacity }) => {
  const { t, i18n } = useTranslation();
  const [currLang, setCurrLang] = useState<string>(i18n.language);

  useEffect(() => {
    setCurrLang(i18n.language);
  }, [i18n.language]);
  const [timeOfDay, setTimeOfDay] = useState<TimeOfDay | null>(null);
  useEffect(() => {
    try {
      const position = { latitude: 46.0569, longitude: 14.5058 }; // Ljubljana
      const time = getTimeOfDay(position);
      setTimeOfDay(time);
    } catch (error) {
      console.error("Error getting time of day:", error);
    }
  }, [pbMeter]);
  const energyGrid = () => {
    // navigate("/energy?id=4");
  };
  const energyBattery = () => {
    // navigate("/energy?id=3");
  };
  const energyHome = () => {
    // navigate("/energy?id=1");
  };
  const energySolar = () => {
    // navigate("/energy?id=2");
  };
  return (
    <div className={"sky py-3 " + (timeOfDay || "")}>
      <div className={`relative ${className ? className : ""}`} style={{ height: "auto", opacity }}>
        {pbMeter.ac_w_tot >= 50 && <LottieAnimation id="stevBatPb" animationData={pb_stevec_baterija} />}
        {pbMeter.ac_w_tot <= -50 && <LottieAnimation id="batStevPb" animationData={pb_baterija_stevec} />}
        {pbMeter.meter_ac_w >= 50 && <LottieAnimation id="gridStevPb" animationData={pb_omrezje_stevec} />}
        {pbMeter.meter_ac_w <= -50 && <LottieAnimation id="stevGridPb" animationData={pb_stevec_omrezje} />}
        {pbMeter.build_ac_w >= 50 && <LottieAnimation id="stevStavPb" animationData={pb_stecev_stavba} />}
        {pbMeter.build_ac_w <= -50 && <LottieAnimation id="stavStevPb" animationData={pb_stavba_stevec} />}
        {extractSolar(pbSolar) * 1000 >= 50 && <LottieAnimation id="stevSolarPb" animationData={pb_solar_animation} />}
        {extractSolar(pbSolar) * 1000 <= -50 && <LottieAnimation id="solarStevPb" animationData={pb_solar_animation} />}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width="100%"
          height="100%"
          viewBox="0 0 1082 1134"
        >
          <defs>
            <clipPath id="uwlua">
              <path
                fill="#fff"
                d="M966.448 850c6.351 0 11.5 5.149 11.5 11.5s-5.149 11.5-11.5 11.5-11.5-5.149-11.5-11.5 5.149-11.5 11.5-11.5z"
              />
            </clipPath>
            <clipPath id="uwlub">
              <path
                fill="#fff"
                d="M206.5 893c6.351 0 11.5 5.149 11.5 11.5S212.85 916 206.5 916c-6.352 0-11.5-5.149-11.5-11.5s5.148-11.5 11.5-11.5z"
              />
            </clipPath>
            <clipPath id="uwluc">
              <path
                fill="#fff"
                d="M206.5 250c6.351 0 11.5 5.148 11.5 11.5 0 6.351-5.149 11.5-11.5 11.5S195 267.85 195 261.5c0-6.352 5.149-11.5 11.5-11.5z"
              />
            </clipPath>
            <clipPath id="uwlud">
              <path
                fill="#fff"
                d="M892.5 593c6.35 0 11.5 5.148 11.5 11.5 0 6.35-5.15 11.5-11.5 11.5-6.352 0-11.5-5.15-11.5-11.5 0-6.352 5.148-11.5 11.5-11.5z"
              />
            </clipPath>
          </defs>
          <g>
            <g>
              <image width="1082" height="1134" transform="translate(0 145)" xlinkHref={industryImg} />
            </g>
            <path id="crtaBat" fill="#7e8a8a" d="M206 916h1v190h-1z" />
            <g id="batteryLine">
              <g>
                <path fill="#141317" d={`M200 750l9 2v168l-9-2z`} />
              </g>
              <g>
                <path
                  fill="#1fde2d"
                  d={`M200 ${750 + (168 * (100 - pbMeter.batt_soc_avg)) / 100}l9 2v${168 * (pbMeter.batt_soc_avg / 100)}l-9-2z`}
                />
              </g>
            </g>
            <g id="gridLine">
              <path fill="#7e8a8a" d="M967 1141h-1V833h1z" transform="translate(-250 -35)" />
            </g>
            <g>
              <path
                fill="#7e8a8a"
                transform="translate(-250 -52)"
                d="M966.448 850c6.351 0 11.5 5.149 11.5 11.5s-5.149 11.5-11.5 11.5-11.5-5.149-11.5-11.5 5.149-11.5 11.5-11.5z"
              />
              <path
                fill="none"
                stroke="#5eba47"
                strokeLinecap="round"
                transform="translate(-250 -52)"
                strokeLinejoin="round"
                strokeMiterlimit="20"
                strokeWidth="12"
                d="M966.448 850c6.351 0 11.5 5.149 11.5 11.5s-5.149 11.5-11.5 11.5-11.5-5.149-11.5-11.5 5.149-11.5 11.5-11.5z"
                clipPath='url("#uwlua")'
              />
            </g>
            <g
              id="gridOpen"
              onClick={() => {
                energyGrid();
              }}
            >
              <g>
                <g transform="translate(50 20)">
                  {pbMeter.meter_ac_w === null ? (
                    <g transform="translate(686 1044)">
                      <DotSpinner />
                    </g>
                  ) : (
                    <text fill="#fff" fontFamily="inherit" fontSize="3.4rem" transform="translate(686 1064)">
                      <tspan dy="0.4em">{formatPower(pbMeter.meter_ac_w)} kW</tspan>
                    </text>
                  )}
                </g>
              </g>
              <g>
                <g transform="translate(50 20)">
                  <text fill="#5eba47" fontFamily="inherit" fontSize="2.4rem" transform="translate(686 1025)">
                    <tspan dy="0em">{t("animation.grid")}</tspan>
                  </text>
                </g>
              </g>
            </g>
            <g onClick={energyBattery}>
              <g>
                <g>
                  {pbMeter.meter_ac_w === null ? (
                    <g transform="translate(220 1065)">
                      <DotSpinner />
                    </g>
                  ) : (
                    <text fill="#fff" fontFamily="inherit" fontSize="3.4rem" transform="translate(220 1065)">
                      <tspan dy="0.8em">
                        {formatPower(pbMeter.ac_w_tot)} kW - {pbMeter.batt_soc_avg.toFixed(2)}%
                      </tspan>
                    </text>
                  )}
                </g>
              </g>
              <g>
                <g>
                  <text fill="#5eba47" fontFamily="inherit" fontSize="2.4rem" transform="translate(224 1025)">
                    <tspan dy="0.6em">{t("animation.battery")}</tspan>
                  </text>
                </g>
              </g>
            </g>
            <g onClick={energySolar}>
              <g>
                <g>
                  {pbMeter.meter_ac_w === null ? (
                    <g>
                      {currLang === "sl" ? (
                        <g transform="translate(226 40)">
                          <DotSpinner />
                        </g>
                      ) : (
                        <g transform="translate(226 0)">
                          <DotSpinner />
                        </g>
                      )}
                    </g>
                  ) : (
                    <text fill="#fff" fontFamily="inherit" fontSize="3.4rem" transform="translate(226 39)">
                      <tspan dy="1em">{extractSolar(pbSolar)} kW</tspan>
                    </text>
                  )}
                </g>
              </g>
              <g>
                <g>
                  <text fill="#5eba47" fontFamily="inherit" fontSize="2.4rem" transform="translate(225 2)">
                    <tspan dy="0.8em">{t("animation.solar")}</tspan>
                  </text>
                </g>
              </g>
            </g>
            <g>
              <path fill="#7e8a8a" d="M206 2h1v250h-1z" />
            </g>
            <g>
              <path
                fill="#7e8a8a"
                d="M206.5 250c6.351 0 11.5 5.148 11.5 11.5 0 6.351-5.149 11.5-11.5 11.5S195 267.85 195 261.5c0-6.352 5.149-11.5 11.5-11.5z"
              />
              <path
                fill="none"
                stroke="#5eba47"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="20"
                strokeWidth="12"
                d="M206.5 250c6.351 0 11.5 5.148 11.5 11.5 0 6.351-5.149 11.5-11.5 11.5S195 267.85 195 261.5c0-6.352 5.149-11.5 11.5-11.5z"
                clipPath='url("#uwluc")'
              />
            </g>
            <g>
              <path fill="#7e8a8a" d="M892 0h1v600h-1z" />
            </g>
            <g>
              <path
                fill="#7e8a8a"
                d="M892.5 593c6.35 0 11.5 5.148 11.5 11.5 0 6.35-5.15 11.5-11.5 11.5-6.352 0-11.5-5.15-11.5-11.5 0-6.352 5.148-11.5 11.5-11.5z"
              />
              <path
                fill="none"
                stroke="#5eba47"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="20"
                strokeWidth="12"
                d="M892.5 593c6.35 0 11.5 5.148 11.5 11.5 0 6.35-5.15 11.5-11.5 11.5-6.352 0-11.5-5.15-11.5-11.5 0-6.352 5.148-11.5 11.5-11.5z"
                clipPath='url("#uwlud")'
              />
            </g>
            <g onClick={energyHome}>
              <g>
                <g>
                  {pbMeter.meter_ac_w === null ? (
                    <g transform="translate(760 40)">
                      <DotSpinner />
                    </g>
                  ) : (
                    <text fill="#fff" fontFamily="inherit" fontSize="3.4rem" transform="translate(880 69)" textAnchor="end">
                      {/* <tspan dy="0.5em">{formatPower(pbMeter.build_ac_w)} kW</tspan> */}
                      <tspan dy="0.5em">{sumEnergy(pbSolar, pbMeter.ac_w_tot, pbMeter.meter_ac_w)} kW</tspan>
                    </text>
                  )}
                </g>
              </g>
              <g>
                <g>
                  <text fill="#5eba47" fontFamily="inherit" fontSize="2.4rem" transform="translate(780 2)" textAnchor="end">
                    <tspan x="100" dy="0.8em">
                      {t("animation.site")}
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </div>
  );
};

export default AnimationPowerPlant;
